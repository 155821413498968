<template>
  <router-view />
  <img v-if="loaded" class="elv-content-skeleton-logo" src="@/assets/img/left-meun-whole-logo.png" alt="elven-logo" />

  <SwitchVersionDialog v-if="isShowSwitchVersionDialog" :user-id="user?.userId ? String(user?.userId) : ''" />
</template>
<script setup lang="ts">
import '@/assets/fonts/font.css'
import '@/assets/style/common.scss'
import '@/assets/style/element-reset.scss'
import { useCookies } from 'vue3-cookies'
import { useGlobalStore } from '@/stores/modules/global/index'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import SwitchVersionDialog from '@/components/SwitchVersionDialog.vue'

const { cookies } = useCookies()
const globalStore = useGlobalStore()
const userGlobalStore = useUserGlobalStore()
const { loaded } = storeToRefs(globalStore)
const { user } = storeToRefs(userGlobalStore)

const isShowSwitchVersionDialog = computed(() => {
  return (
    user.value?.userId &&
    cookies.get('elv-app-token') &&
    cookies.get(`elv-app-v2Used-${user.value.userId}`) === null &&
    !loaded.value
  )
})
</script>
<style scoped>
.elv-content-skeleton-logo {
  width: 200px;
  height: 64px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
