import { createRouter, createWebHistory } from 'vue-router'
import globalGuard from './globalGuard'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    //   登录页面
    {
      name: 'login',
      path: '/login',
      component: () => import('@/pages/User/LoginPage.vue'),
      meta: { requiresAuth: false }
    },
    // 注册页面
    {
      name: 'signup',
      path: '/signup',
      component: () => import('@/pages/User/SignupPage.vue'),
      meta: { requiresAuth: false }
    },
    // 付费简介页面
    {
      name: 'price',
      path: '/price',
      component: () => import('@/pages/Price/IndexPage.vue'),
      meta: { requiresAuth: false }
    },
    // 关于我们页面
    {
      name: 'about',
      path: '/about',
      component: () => import('@/pages/About/IndexPage.vue'),
      meta: { requiresAuth: false }
    },
    // 招聘页面
    {
      name: 'careers',
      path: '/careers',
      component: () => import('@/pages/Careers/IndexPage.vue'),
      meta: { requiresAuth: false }
    },
    //   主体业务
    {
      name: 'defaultLayout',
      path: '/', // 这个并不是通配符，而是严格匹配
      component: () => import('@/layout/index.vue'),
      children: [
        {
          name: 'reports',
          path: 'reports/:entityId',
          redirect: () => ({ name: 'reports-dashboard' }),
          meta: { requiresAuth: true },
          component: () => import('@/pages/Financials/DetailPage.vue'),
          children: [
            {
              name: 'reports-dashboard',
              path: 'dashboard',
              component: () => import('@/pages/Financials/Project/Dashboard/IndexPage.vue')
            },
            {
              name: 'reports-balance-sheet',
              path: 'balance-sheet',
              component: () => import('@/pages/Financials/Project/Reports/BalancePage.vue')
            },
            {
              name: 'reports-income-statement',
              path: 'income-statement',
              component: () => import('@/pages/Financials/Project/Reports/IncomePage.vue')
            },
            {
              name: 'reports-cash-flow-statement',
              path: 'cash-flow-statement',
              component: () => import('@/pages/Financials/Project/Reports/CashFlowPage.vue')
            },
            {
              name: 'reports-general-ledger',
              path: 'general-ledger',
              component: () => import('@/pages/Financials/Project/Reports/GeneralLedgerPage.vue')
            },
            {
              name: 'reports-transactions',
              path: 'transactions',
              component: () => import('@/pages/Financials/Project/Transactions/IndexPage.vue'),
              redirect: () => ({ name: 'reports-transactions-for-review' }),
              children: [
                {
                  name: 'reports-transactions-for-review',
                  path: 'for-review',
                  component: () => import('@/pages/Financials/Project/Transactions/ForReviewPage.vue')
                },
                {
                  name: 'reports-transactions-duplicates',
                  path: 'duplicates',
                  component: () => import('@/pages/Financials/Project/Transactions/DuplicatesPage.vue')
                },
                {
                  name: 'reports-transactions-categorized',
                  path: 'categorized',
                  component: () => import('@/pages/Financials/Project/Transactions/ReviewedPage.vue')
                }
              ]
            },
            {
              name: 'reports-significant-holdings',
              path: 'significant-holdings',
              component: () => import('@/pages/Financials/Project/Reports/HoldingsPage.vue')
            },
            {
              name: 'reports-restrictions',
              path: 'restrictions-crypto-assets',
              component: () => import('@/pages/Financials/Project/Reports/RestrictionsPage.vue')
            },
            {
              name: 'reports-roll-forward',
              path: 'crypto-assets-roll-forward',
              component: () => import('@/pages/Financials/Project/Reports/RollForwardPage.vue')
            },
            {
              name: 'reports-sources',
              path: 'sources',
              component: () => import('@/pages/Financials/Project/Sources/IndexPage.vue'),
              redirect: () => ({ name: 'reports-sources-detail' }),
              children: [
                {
                  name: 'reports-sources-detail',
                  path: 'detail',
                  component: () => import('@/pages/Financials/Project/Sources/DetailPage.vue')
                },
                {
                  name: 'reports-sources-accounts',
                  path: 'accounts',
                  component: () => import('@/pages/Financials/Project/Sources/AccountsPage.vue')
                }
              ]
            },
            {
              name: 'reports-rules',
              path: 'rules',
              component: () => import('@/pages/Financials/Project/Rules/IndexPage.vue')
            },
            {
              name: 'reports-contacts',
              path: 'contacts',
              component: () => import('@/pages/Financials/Project/Contacts/IndexPage.vue'),
              redirect: () => ({ name: 'reports-contacts-named' }),
              children: [
                {
                  name: 'reports-contacts-named',
                  path: 'named',
                  component: () => import('@/pages/Financials/Project/Contacts/NamedPage.vue')
                },
                {
                  name: 'reports-contacts-unnamed',
                  path: 'unnamed',
                  component: () => import('@/pages/Financials/Project/Contacts/UnnamedPage.vue')
                }
              ]
            }
          ]
        },
        {
          name: 'project-settings',
          path: '/project/:projectId/settings',
          meta: { requiresAuth: true },
          component: () => import('@/pages/Financials/ProjectSetting/DetailPage.vue')
        },
        {
          name: 'entity-settings',
          path: '/project/:projectId/entity/:entityId/setting',
          meta: { requiresAuth: true },
          component: () => import('@/pages/Financials/EntitySetting/DetailPage.vue')
        },
        {
          name: 'landingPage',
          path: '/landing',
          redirect: () => ({ name: 'landing-apply' }),
          component: () => import('@/pages/Landing/DetailPage.vue'),
          meta: { requiresAuth: true },
          children: [
            {
              name: 'landing-pricing',
              path: 'pricing',
              component: () => import('@/pages/Landing/PricingPage.vue')
            },
            {
              name: 'landing-apply',
              path: 'apply',
              component: () => import('@/pages/Landing/ApplyPage.vue')
            }
          ]
        },
        // 隐私协议页面
        {
          name: 'privacyAgreement',
          path: '/pact/privacyAgreement',
          component: () => import('@/pages/Pact/PrivacyAgreement.vue'),
          meta: { requiresAuth: false, hasSider: false }
        },
        // 服务协议页面
        {
          name: 'serviceAgreement',
          path: '/pact/serviceAgreement',
          component: () => import('@/pages/Pact/ServiceAgreement.vue'),
          meta: { requiresAuth: false, hasSider: false }
        }
      ]
    },
    {
      name: 'oauth-xero',
      path: '/oauth/xero',
      meta: { requiresAuth: true },
      component: () => import('@/pages/Oauth/ExroPage.vue')
    },
    {
      name: 'oauth-google',
      path: '/oauth/google',
      meta: { requiresAuth: true },
      component: () => import('@/pages/Oauth/GooglePage.vue')
    },
    // 404 页面
    {
      name: 'notFound',
      path: '/404',
      component: () => import('@/pages/NotFoundPage.vue'),
      meta: { requiresAuth: false }
    },

    // 所有未被匹配的路由都漏到这里，然后重定向到home
    {
      path: '/:pathMatch(.*)',
      name: 'not-found',
      redirect: () => ({ name: 'notFound' })
    }
  ]
})

router.beforeEach(globalGuard.init)
router.beforeEach(globalGuard.authVerify)
router.afterEach(globalGuard.versionCheck)
router.afterEach(globalGuard.mixpanelStatistics)

export default router
