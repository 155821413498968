import { http } from '@/lib/http'
import { UpdateImageFileType, ProjectSecurityLogType, ProjectSecurityLogParamsType } from '#/ProjectTypes'

export default {
  /**
   * 申请试用
   * @param {Object} data
   * @property {string} organization
   * @property {string} position
   * @property {string} industry
   * @property {string} historyTransactionCount
   * @property {string} otherProducts
   * @property {string} reason
   */
  projectApplyTrial: (data: object) => {
    return http.request('post', '/project/applyTrial', { data })
  },
  /**
   * 取消申请
   */
  projectCancelTrial: () => {
    return http.request('post', '/project/cancelTrial')
  },
  /**
   * 获取试用详情
   */
  getProjectTrialDetail: () => {
    return http.request('post', '/project/getTrial')
  },
  /**
   * 上传图片
   * @param {Object} data
   * @property {file} file
   */
  uploadFile: (data: object) => {
    return http.request<ResponseData<UpdateImageFileType>>('post', '/project/upload', { data })
  },
  /**
   * 创建免费项目
   * @param {Object} data
   * @property {string} name
   * @property {string} logo
   * @property {string} costBasisMethodology
   * @property {string} region
   * @property {string} defaultCurrency
   * @property {string} unit
   * @property {string} creationDate
   */
  projectCreateByTrial: (data: object) => {
    return http.request('post', '/project/createByTrial', { data })
  },
  /**
   * 创建免费项目
   * @param {Object} data
   * @property {string} name
   * @property {string} logo
   * @property {string} costBasisMethodology
   * @property {string} region
   * @property {string} defaultCurrency
   * @property {string} unit
   * @property {string} creationDate
   */
  createFreeProject: (data: object) => {
    return http.request('post', '/project', { data })
  },
  /**
   * 创建Entity
   * @param {number} projectId 项目id
   * @param {Object} data
   * @property {string} name
   * @property {string} logo
   * @property {string} costBasisMethodology
   * @property {string} region
   * @property {string} defaultCurrency
   * @property {string} unit
   * @property {string} creationDate
   */
  createProjectEntity: (projectId: number, data: object) => {
    return http.request('post', `/project/${projectId}/entity`, { data })
  },
  /**
   * 删除Entity
   * @param {number} entityId 主体id
   */
  deleteProjectEntity: (entityId: number) => {
    return http.request('delete', `/entity/${entityId}`)
  },
  /**
   * 获取Entity详情
   * @param {string} entityId
   */
  getEntityDetail: (entityId: number) => {
    return http.request('get', `/entity/${entityId}`)
  },
  /**
   * 获取项目列表接口
   */
  getProjectList: () => {
    return http.request('get', `/project`)
  },
  /**
   * 编辑Entity
   * @param {Object} data
   * @property {string} name
   * @property {string} logo
   * @property {string} costBasisMethodology
   * @property {string} region
   * @property {string} defaultCurrency
   * @property {string} unit
   * @property {string} creationDate
   */
  editEntityDetail: (entityId: number, data: object) => {
    return http.request('put', `/entity/${entityId}`, { data })
  },
  /**
   * 获取entity列表
   * @param {string} projectId
   */
  getEntityList: (projectId: number) => {
    return http.request('get', `/project/${projectId}/entity`)
  },
  /**
   * 获取项目成员列表
   * @param {string} projectId
   */
  getProjectMemberList: (projectId: number) => {
    return http.request('get', `/project/${projectId}/member`)
  },
  /**
   * 获取entity成员列表
   * @param {string} entityId
   */
  getEntityMemberList: (entityId: number) => {
    return http.request('get', `/entity/${entityId}/member`)
  },
  /**
   * 添加项目成员
   * @param {string} projectId
   * @param {Object} data
   */
  addProjectMember: (projectId: number, data: object) => {
    return http.request('post', `/project/${projectId}/member`, { data })
  },
  /**
   * 删除项目成员
   * @param {string} projectId
   * @param {string} userId
   */
  deleteProjectMember: (projectId: number, userId: number) => {
    return http.request('delete', `/project/${projectId}/member/${userId}`)
  },
  /**
   * 编辑项目成员权限
   * @param {number} projectId
   * @param {string} userId
   * @param {Object} data
   */
  editProjectMember: (projectId: number, userId: number, data: object) => {
    return http.request('put', `/project/${projectId}/member/${userId}`, { data })
  },
  /**
   * 获取项目详情
   * @param {number} projectId
   */
  getProjectDetail: (projectId: number) => {
    return http.request('get', `/project/${projectId}`)
  },
  /**
   * 修改项目名称
   * @param {number} projectId
   * @param {Object} data
   * @property {string} name
   */
  editProjectName: (projectId: number, data: object) => {
    return http.request('put', `/project/${projectId}`, { data })
  },
  /**
   * 重新发送邀请邮件
   * @param {number} projectId
   * @param {number} userId
   */
  resendInvitationEmail(projectId: number, userId: number) {
    return http.request('post', `/project/${projectId}/member/${userId}/resendInvitation`)
  },
  /**
   * 获取用户操作log日志
   * @param {number} projectId
   * @param {Object} params
   * @property {number} page
   * @property {number} limit
   */
  getProjectLog: (projectId: number, params: ProjectSecurityLogParamsType) => {
    return http.request<ResponseData<ProjectSecurityLogType>>('get', `/project/${projectId}/user/log`, { params })
  }
}
