import utils from './utils'
import { stringify } from 'qs'
import { useCookies } from 'vue3-cookies'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { HttpError, RequestMethods, HttpResponse, HttpRequestConfig } from '#/http.d'
import axios, { AxiosInstance, AxiosRequestConfig, CustomParamsSerializer } from 'axios'

const { cookies } = useCookies()

// 相关配置请参考：www.axios-js.com/zh-cn/docs/#axios-request-config-1
const defaultConfig: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_API_URL,
  // 请求超时时间
  timeout: 30000,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
  // 数组格式参数序列化（https://github.com/axios/axios/issues/5142）
  paramsSerializer: {
    serialize: stringify as unknown as CustomParamsSerializer
  }
}

class ElvenHttp {
  constructor() {
    this.httpInterceptorsRequest()
    this.httpInterceptorsResponse()
  }

  /** 初始化配置对象 */
  private static initConfig: HttpRequestConfig = {}

  /** 保存当前Axios实例对象 */
  private static axiosInstance: AxiosInstance = axios.create(defaultConfig)

  /** 请求拦截器 */
  // eslint-disable-next-line class-methods-use-this
  private httpInterceptorsRequest(): void {
    ElvenHttp.axiosInstance.interceptors.request.use(
      async (config: HttpRequestConfig) => {
        // 优先判断post/get等方法是否传入回掉，否则执行初始化设置等回掉
        if (typeof config.beforeRequestCallback === 'function') {
          config.beforeRequestCallback(config)
          return config
        }
        if (ElvenHttp.initConfig.beforeRequestCallback) {
          ElvenHttp.initConfig.beforeRequestCallback(config)
          return config
        }

        return new Promise((resolve) => {
          const token = cookies.get('elv-app-token')
          if (config.headers && token) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${token}`
          }
          resolve(config)
        })
      },
      (error: any) => {
        console.log(error)
        return Promise.reject(error)
      }
    )
  }

  /** 响应拦截器 */
  // eslint-disable-next-line class-methods-use-this
  private httpInterceptorsResponse(): void {
    const instance = ElvenHttp.axiosInstance
    instance.interceptors.response.use(
      (response: HttpResponse) => {
        const $config = response.config
        // 优先判断post/get等方法是否传入回掉，否则执行初始化设置等回掉
        if (typeof $config.beforeResponseCallback === 'function') {
          $config.beforeResponseCallback(response)
          return response.data
        }
        if (ElvenHttp.initConfig.beforeResponseCallback) {
          ElvenHttp.initConfig.beforeResponseCallback(response)
          return response.data
        }

        if (response.data.status === 'success') {
          return response.data
        }

        return Promise.reject(response.data)
      },
      (error: HttpError) => {
        const $error = error
        $error.isCancelRequest = axios.isCancel($error)

        // 获取状态码数据
        if (error.response.status === 400 || error.response.status === 401) {
          if (error.response.status === 401) {
            const userGlobalStore = useUserGlobalStore()
            if (userGlobalStore.user?.userId) {
              window.localStorage.setItem('onlyId', String(userGlobalStore.user?.userId))
            }
            cookies.remove('elv-app-token', '/', import.meta.env.VITE_APP_COOKIES_DOMAIN)
            window.location.href = '/login'
          }
          return Promise.reject(error.response.data.data)
        }

        // 所有的响应异常 区分来源为取消请求/非取消请求

        // 不认识的错误直接拼接一下格式
        const errorMessage = {
          code: -1,
          message: utils.formatLanguageContent(error.message)
        }
        return Promise.reject(errorMessage)
      }
    )
  }

  /** 通用请求工具函数 */
  // eslint-disable-next-line class-methods-use-this
  public request<T = ResponseData>(
    method: RequestMethods,
    url: string,
    param?: AxiosRequestConfig,
    axiosConfig?: HttpRequestConfig
  ): Promise<T> {
    const config = {
      method,
      url,
      ...param,
      ...axiosConfig
    } as HttpRequestConfig

    // 单独处理自定义请求/响应回掉
    return new Promise((resolve, reject) => {
      ElvenHttp.axiosInstance
        .request(config)
        .then((response: any) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /** 单独抽离的post工具函数 */
  public post<T, P>(url: string, params?: AxiosRequestConfig<T>, config?: HttpRequestConfig): Promise<P> {
    return this.request<P>('post', url, params, config)
  }

  /** 单独抽离的get工具函数 */
  public get<T, P>(url: string, params?: AxiosRequestConfig<T>, config?: HttpRequestConfig): Promise<P> {
    return this.request<P>('get', url, params, config)
  }
}

export const http = new ElvenHttp()
