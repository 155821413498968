import type { Directive } from 'vue'
import defaultImg from '@/assets/img/default-logo.png'

const lazyImg: Directive = {
  mounted(el: HTMLImageElement, { value }) {
    const observer = new IntersectionObserver(([{ isIntersecting }]) => {
      if (isIntersecting) {
        // 图片加载失败显示默认图片
        el.onerror = () => {
          el.src = defaultImg
        }
        el.src = value
        // 中止监听
        observer.unobserve(el)
      }
    })
    observer.observe(el)
  }
}

export default lazyImg
