export default {
  zh: {
    Reports: '报告',
    Fundamentals: '基本面',
    'Money Flows': '资金流',
    'Price & MarketCap': '价格和市值',
    'Exchange Flows': '交易所流动性',
    'Top Holder': '头部持有者',
    Social: '社交媒体',
    'Social Trending': '社交热点',
    KOL: '意见领袖',
    Market: '市场',
    'Market Map': '市场地图',
    'Emerging Spaces': '新兴领域',
    Heatmap: '热度图',
    'My Screener': '我的筛选器',
    Alert: '我的提醒',
    Rules: '规则设置',
    History: '历史记录',
    Metrics: '指标',
    Charts: '图表',
    'Balance Sheet': '资产负债表',
    'Income Statement': '利润表',
    'Cash Flow Statement': '现金流量表',
    'General Ledger': '总账表',
    Wallets: '钱包地址表',
    'Please confirm your email to Log in.': '请确认您的电子邮件以登录。',
    'Welcome to': '欢迎来到',
    'Enter the correct email address': '输入正确的电子邮件地址',
    'Please enter the correct email address': '请输入正确的电子邮件地址',
    'Please enter the correct email address.': '请输入正确的电子邮件地址。',
    'Enter verification code': '输入验证码',
    'Please enter verification code.': '请输入验证码。',
    'Please input Email': '请输入电子邮件',
    'Please input password': '请输入密码',
    'Please input code': '请输入验证码',
    Success: '成功',
    'Copy failed': '复制失败',
    'Copy successfully': '复制成功',
    'I have read & agree to the Terms of Service ': '我已阅读并同意服务条款',
    'Powerful editing capabilities make the data display clearer.': '强大的编辑功能使数据显示更清晰。',
    'Create my own screener！': '创建我的筛选器！',
    'You must set a password, later can password login.': '您必须设置密码，稍后可以使用密码登录。',
    '22 contract address on 1 chain': '基于1个链上的22条地址',
    'Only the latest 20 items in each account are displayed, if you need to see all the data, please Select the accounting period:':
      '每个账户仅显示最新的20条数据，如果您需要查看所有数据，请选择会计期间：',
    'Sent At UTC 00 ：30 Every Day': '每天UTC 00:30发送',
    'You haven’t created any alert.': '您尚未创建任何提醒。',
    ok: '确定',
    send: '发送',
    seconds: '秒',
    Login: '登录',
    'Log out': '注销',
    save: '保存',
    'save as': '另存为',
    'Contact Us': '联系我们',
    Filter: '筛选',
    'Group By': '分组',
    'Edit Table': '编辑表格',
    Export: '导出',
    DisCard: '丢弃',
    'Add group': '添加分组',
    'Rules for Subtotal': '小计规则',
    'Sub Group': '子分组',
    'Save Changes': '保存更改',
    Cancel: '取消',
    'Add condition': '添加条件',
    team: '团队',
    password: '密码',
    'No Data': '无数据',
    'Verification Code': '验证码',
    Address: '地址',
    'All rights reserved.': '版权所有。',
    'Updated on': '更新时间',
    'Data source': '数据来源',
    Share: '分享',
    Financials: '财务',
    Business: '业务',
    Valuation: '估值',
    'From 2019/05/07 to 2023/02/20': '从2019年5月7日到2023年2月20日',
    List: '列表',
    results: '结果',
    Group: '分组',
    'Secondary Group': '二级分组',
    COLOR: '颜色',
    BLOCK: '块',
    SIZE: '大小',
    'Y Axise': 'Y轴',
    'X Axise': 'X轴',
    'Group VIew': '分组视图',
    Choose: '选择',
    Stick: '固定',
    'Hide Column': '隐藏列',
    'Sort ascending': '升序排列',
    'Sort descending': '降序排列',
    Monitor: '监控',
    Frequency: '频率',
    name: '名称',
    Condition: '条件',
    SOON: 'SOON',
    'In millions of USD': '单位：百万美元',
    'Loans to customers': '客户贷款',
    'Deposits from customers': '客户存款',
    'Share capital and share premium': '股本和股本溢价',
    'Fee and commission income': '手续费和佣金收入',
    'Interest expenses': '利息费用',
    'Non-operating income': '营业外收入',
    'Non-operating expenses': '营业外支出',
    'Currency exchange gain/(loss)': '汇兑收益/(亏损)',
    'Opening balance': '期初余额',
    'Closing balance': '期末余额',
    Mint: '铸币',
    Borrow: '借入',
    'Journal Entries': '日记账条目',
    'Checks borrower collateral value, and if sufficient, transfers the underlying asset out of the market to borrower, and updates borrower’s borrow balance.':
      '检查借款人抵押品价值，如果足够，则将基础资产从市场转移给借款人，并更新借款人的借款余额。',
    'Redeem - interest': '赎回-利息部分',
    'Redeem - principal': '赎回-本金部分',
    'Repay borrow - interest': '偿还借款-利息部分',
    'Repay borrow - principal': '偿还借款-本金部分',
    'Reserve added': '增加储备金',
    'Mark to market': '按市值计价',
    "Transfers an underlying asset into the market, updates minter's cToken balance.":
      '将基础资产转移到市场中，更新铸币者的cToken余额。',
    'Measuring the fair value of accounts that can fluctuate over time, such as assets and liabilities':
      '测量随时间波动的账户的公允价值，例如资产和负债。',
    'Payments to related parties such as OpenZeppelin': '向OpenZeppelin等相关方付款',
    'Refunds from users who had overpaid during vulnerability exploits': '对在漏洞利用期间支付过多的用户进行退款',
    'Refunds from related parties': '相关方退款',
    'Accrue interest - all': '计提利息-全部',
    'Receive retail - revenue': '普通用户转入-收入部分',
    'Send retail - revenue': '普通用户转出-收入部分',
    'Internal transfer disclosed - cost': '公开地址内部转账-费用部分',
    'Send institution - cost': '机构用户转出-费用部分',
    'Send retail - principal': '普通用户转出-本金部分',
    'Internal transfer undisclosed - principal out': '未公开地址内部转账-本金转出',
    'Receive retail - principal': '普通用户转入-本金部分',
    'Send institution - principal': '机构用户转出-本金部分',
    'Internal transfer undisclosed - cost': '未公开地址内部转账-费用部分',
    'Receive institution - revenue': '机构用户转入-收入部分',
    'Send retail - cost': '普通用户转出-支出部分',
    'Internal transfer undisclosed - principal in': '未公开地址内部转账-本金转入',
    'Receive institution - principal': '机构用户转入 - 本金部分',
    'Interest attributed to compound': '归属于Compound的利息',
    'Total revenue': '总收入',
    'Total cost of sales': '总销售成本',
    'Net revenue': '毛利',
    'Incentives to compound community normal users': '向Compound社区普通用户提供激励',
    'Total expenses': '总费用',
    'Total expense': '总费用',
    'Non-operating income and expenses': '非营业性收支',
    'Total non-operating income and expenses': '非营业性收支总额',
    'Net (loss) income for the period': '当期净（亏损）收益',
    'Transaction revenue': '交易收入',
    'Cash and cash equivalent, including cryptocurrencies': '包括加密货币的现金及现金等价物',
    'Loans to borrowers': '借款人贷款',
    'Interest receviable': '应收利息',
    'Total assets': '总资产',
    'The interest due to depositors': '应付给存款人的利息',
    'Total liabilities': '总负债',
    'Total equity': '总股东权益',
    'Total liabilities and equity': '总负债和股东权益',
    'Customer crypto assets': '客户加密货币资产',
    'Cash flows from operating activities': '由经营活动产生的现金流',
    'Repayments from borrowers': '借款人还款',
    'Repayments from liquidated borrowers': '被清算的借款人还款',
    'Interest received': '已收利息',
    'Reserves added from fees': '收取费用增加的储备',
    'Redemptions from depositors': '存款人赎回',
    'Interest paid': '已付利息',
    'Transfers from unknown addresses': '未知地址的转账',
    'Crypto payments to related parties': '向关联方支付加密货币',
    'Crypto refunds from related parties': '来自关联方的加密货币退款',
    'Refunds from hacking/attackings': '来自黑客攻击的退款',
    'Net cash used in operating activities': '经营活动中使用的净现金',
    'Cash flows from financing activities': '筹资活动现金流量',
    'Investment from investors': '来自投资者的投资',
    'Unrealized gain/(loss)': '未实现的收益/(损失)',
    'Net cash from financing activities': '融资活动产生的净现金流量',
    'Net (decrease) increase in cash and cash equivalents': '现金及现金等价物净(减少)增加额',
    'Cash and cash equivalents at the beginning of period': '期初现金及现金等价物余额',
    'Cash and cash equivalents at the end of period': '期末现金及现金等价物余额',
    'Transaction revenue from customers': '来自用户的交易收入',
    'Transaction fee for customer transaction': '用户交易手续费',
    'Transaction fee for internal transaction': '内部交易手续费',
    Chain: '区块链',
    Ecosystem: '生态系统',
    DAU: '日活跃用户数',
    'Avg DAU(7D)': '7天平均日活跃用户数',
    'Avg DAU(30D)': '30天平均日活跃用户数',
    'DAU Change(1D)': '1天日活跃用户数变化',
    'DAU Change(7D)': '7天日活跃用户数变化',
    'DAU Change(30D)': '30天日活跃用户数变化',
    TVL: '锁定价值总额',
    'MC/TVL': '市值/锁定价值比率',
    'TVL Change(1D)': '1天锁定价值总额变化',
    'TVL Change(30D)': '30天锁定价值总额变化',
    'GMV(1D)': '1天总交易额',
    'GMV(30D)': '30天总交易额',
    'GMV Change(1D)': '1天总交易额变化',
    'GMV Change(30D)': '30天总交易额变化',
    'Total Revenue(1D)': '1天总收入',
    'Total Revenue(30D)': '30天总收入',
    'Total Revenue Change(1D)': '1天总收入变化',
    'Total Revenue Change(30D)': '30天总收入变化',
    'Supply-side Revenue(1D)': '1天供应方收入',
    'Supply-side Revenue(30D)': '30天供应方收入',
    'Supply-side Revenue Change(1D)': '1天供应方收入变化',
    'Supply-side Revenue Change(30D)': '30天供应方收入变化',
    'Protocol Revenue(1D)': '1天协议收入',
    'Protocol Revenue(30D)': '30天协议收入',
    'Protocol Revenue Change(1D)': '1天协议收入变化',
    'Protocol Revenue Change(30D)': '30天协议收入变化',
    'Gross Profit Margin(1D)': '1天毛利率',
    'Gross Profit Margin(30D)': '30天毛利率',
    'Token Incentives(1D)': '1天代币激励',
    'Token Incentives(30D)': '30天代币激励',
    'Token Incentives Change(1D)': '1天代币激励变化',
    'Token Incentives Change(30D)': '30天代币激励变化',
    'Net Profit(1D)': '1天净利润',
    'Net Profit(30D)': '30天净利润',
    'Net Profit Change(1D)': '1天净利润变化',
    'Net Profit Change(30D)': '30天净利润变化',
    EPS: '每股收益',
    'EPS Change(1D)': '1天每股收益变化',
    'EPS Change(30D)': '30天每股收益变化',
    PS: 'PS',
    'PS Change(1D)': 'PS变化（1天）',
    'PS Change(30D)': 'PS变化（30天）',
    PE: '市盈率（PE）',
    'PE Change(1D)': 'PE变化（1天）',
    'PE Change(30D)': 'PE变化（30天）',
    'Circulating MC': '流通市值',
    'MC/FDV': '市值/流通股本比率',
    'Circulating MC Change(1D)': '流通市值变化（1天）',
    'Circulating MC Change(7D)': '流通市值变化（7天）',
    'Fully Diluted MC': '全 diluted 市值',
    'Fully Diluted MC Change(1D)': '全 diluted 市值变化（1天）',
    'Fully Diluted MC Change(7D)': '全 diluted 市值变化（7天）',
    'Circulating Supply': '流通供应量',
    'Fully Diluted Supply': '全 diluted 供应量',
    'Total Supply': '总供应量',
    'Daily Issuance': '每日发行量',
    'Issuance Rate': '发行速率',
    Price: '价格',
    'Price Change(1D)': '价格变化（1天）',
    'Price Change(7D)': '价格变化（7天）',
    'News(1D)': '新闻（1天）',
    'News(30D)': '新闻（30天）',
    'News Change(1D)': '新闻变化（1天）',
    'News Change(30D)': '新闻变化（30天）',
    'Proposals(1D)': '提案（1天）',
    'Proposals(30D)': '提案（30天）',
    'Proposals Change(1D)': '提案变化（1天）',
    'Proposals Change(30D)': '提案变化（30天）',
    'Total Funding Received': '总融资额',
    'Last Round': '最近一轮',
    'History Investors': '历史投资者',
    'Since Last Round': '自上轮融资以来',
    Exchange: '交易所',
    'Total Revenue（30D）': '总收入（30天）',
    'Supply-side Revenue': '供应侧收入',
    'Protocol Revenue': '协议收入',
    'Trading Volume': '交易量',
    Swaps: '交换',
    'Liquidity Added': '新增流动性',
    'Liquidity Removed': '移除流动性',
    Project: '项目',
    Category: '类别',
    Tags: '标签',
    Game: '游戏',
    Transactions: '交易',
    Volume: '成交量',
    Lending: '借贷',
    Deposits: '存款',
    'Deposit Volume': '存款量',
    'Active Deposits': '活跃存款',
    Withdraws: '提现',
    'Withdraw Volume': '提现量',
    Borrows: '借款',
    'Borrow Volume': '借贷量',
    'Active Borrows': '活跃借款',
    Repayments: '还款',
    'Repayment Volume': '还款量',
    Liquidations: '清算',
    'Liquidation Volume': '清算量',
    'Available to Borrow': '可借贷数量',
    'Utilization Ratio': '利用率',
    Collection: '收藏',
    Holder: '持有者',
    Buyer: '买家',
    Seller: '卖家',
    Mints: '铸造',
    Burns: '销毁',
    Sales: '销售',
    Transfers: '转账',
    'Total Minted': '总铸造量',
    'Liquidity Rate': '流动性率',
    'Max Price': '最高价',
    'Avg Price': '平均价',
    'Min Price': '最低价',
    'Floor Price': '底价',
    Royalty: '版税',
    MarketCap: '市值',
    Marketplace: '市场',
    'Sales Transactions': '销售交易',
    'Total Platform Fees': '平台总费用',
    'Avg Platform Fees': '平均平台费用',
    'Total Royalty': '总版税',
    'Avg Royalty': '平均版税',
    Protocol: '协议',
    Pool: '池子',
    APR: '年化利率',
    APY: '年化收益率',
    'Returns Per $1000': '每1000美元回报',
    Yield: '收益率',
    Stage: '阶段',
    Location: '地点',
    Description: '描述',
    Amount: '数量',
    'Investors List': '投资者列表',
    Date: '日期',
    Announcement: '公告',
    Investors: '投资者',
    Investor: '投资者',
    'new old': '新旧',
    Type: '类型',
    'Year Founded': '成立年份',
    'Last Fundraising Round': '上次募资轮次',
    'Last Fundraising Amount': '上次募资金额',
    'Last Fundraising LPs': '上次募资LP数',
    Partner: '合作伙伴',
    'Active Investing': '积极投资',
    'Last Active Date': '上次活跃日期',
    'Total Invested Amount': '总投资金额',
    AUM: '管理资产总额',
    'Fundraising Round': '募资轮次',
    LPs: 'LP数',
    'Portfolio List': '投资组合列表',
    '# Portfolio': '投资组合数量',
    Rounds: '轮次',
    Locations: '地点',
    Website: '网站',
    Twitter: '推特',
    LinkedIn: '领英',
    'Email Address': '电子邮件地址',
    Founder: '创始人',
    'Historic Investors': '历史投资者',
    'Discord/Telegram': 'Discord/Telegram',
    'Accrue interest - user': '用户应计利息',
    Deposit: '存款',
    'Flashloan - all': '闪电贷款 - 全部',
    'LiquidationCall - liquidator withdraw': '清算调用 - 清算者提现',
    'LiquidationCall - pay liquidator': '清算调用 - 支付清算者',
    'Repay - interest': '偿还 - 利息',
    'Repay - principal': '偿还 - 本金',
    'Withdraw - interest': '取款 - 利息',
    'Withdraw - principal': '取款 - 本金',
    Redeem: '赎回',
    Stake: '质押',
    'Mint interest - user': '用户铸造利息',
    'Flashloan - user': '闪电贷款 - 用户',
    'Customer staked liabilities': '客户质押的负债',
    'Customer staked assets': '客户质押的资产',
    'Send institution - revenue': '发送机构 - 收入',
    "Transfers an underlying asset out of the market, updates redeemer's cToken balance.":
      '将基础资产转移出市场，更新赎回者的cToken余额。',
    "Add fees and commission from liquidation to contract's reserve": '将清算的费用和佣金添加到合约的储备中',
    'Investment from investors such as coinbase': '来自投资者（如Coinbase）的投资',
    'Transfers from unknown addresses outside of compound community': '来自Compound社区外未知地址的转账',
    'Mint interest - all': '向所有用户发放利息',
    'Losses from hacking/attacking': '黑客攻击造成的损失',
    'Token incentives to users/ecosystem': '向用户/生态系统提供代币激励',
    'Lossess from attacking events such as vulnerablity exploits happened in 2021':
      '2021年发生的漏洞攻击等事件造成的损失',
    'Comptroller allocate community governance token(COMP) to users': 'Comptroller向用户分配社区治理代币(COMP)',
    'Cash and cash equivalents, including cryptocurrencies': '现金及现金等价物，包括加密货币',
    'Share capital and share premium of compound investors': 'Compound投资者的股本和溢价股份',
    'Accumulated net profit of the corporation that is retained by the corporation': '公司保留的累计净利润',
    'Transfers the underlying asset into the market, updates the borrower’s borrow balance.':
      '将基础资产转入市场，更新借款人的借款余额。',
    'Deposits from users that provide liquidity to compound': '用户提供流动性到Compound的存款',
    'Stakes a certain amount of AAVE tokens, with the option of sending the staked AAVE tokens (stkAAVE) to another address (i.e. the onBehalfOf address)':
      '抵押一定数量的AAVE代币，并有将stkAAVE代币（即onBehalfOf地址）发送到另一个地址的选项',
    'Redeems the staked tokens - receiving AAVE tokens and burning stkAAVE tokens.':
      '赎回抵押的代币 - 收到AAVE代币并烧掉stkAAVE代币。',
    'Withdrawals from depositors': '提现来自存款人',
    'Repayments from liquidator': '来自清算员的偿还',
    'Collateral payment to liquidator': '抵押品支付给清算员',
    'Crypto payment to related parties': '加密货币支付给相关方',
    CATEGORY: '类别',
    BUSINESS: '业务',
    'MARKET CAP': '市值',
    REVENUE: '营收',
    'SOCIAL SIGNAL': '社交信号',
    'TOKEN SUPPLY': '代币供应量',
    TRADE: '交易',
    VALUATION: '估值',
    MEDIAN: '中位数',
    SUM: '求和',
    COUNT: '求总数',
    Info: '信息',
    Returns: '回报',
    Trades: '交易',
    Liquidity: '流动性',
    Usage: '使用情况',
    Financial: '财务',
    Fundraising: '筹资',
    'Borrow Utilization': '借款利用率',
    'Total Revenue': '总收入',
    'Last 90 days': '最近90天',
    'User does not exist': '用户不存在',
    'No permission': '没有权限',
    'User already exists': '用户已存在',
    'The interval between sending verification code is 30 seconds': '验证码发送间隔30秒',
    'Verification code does not exist': '验证码不存在',
    'Verification code has expired': '验证码已过期',
    'Verification code error': '验证码错误',
    'Please log in with your password or verification code': '请填充密码或者验证码进行登录',
    'You have not set a password, please log in with the verification code': '您未设置密码, 请使用验证码登录',
    'Password error': '密码错误',
    'Login after setting password': '设置密码后方可登录',
    'Name should be between 1 and 30 characters': '名字长度在必须在1-30之间',
    'Configuration does not exist': '配置不存在',
    'Configuration already exists': '配置已存在',
    'Query date, project metadata does not exist': '查询日期,项目元数据不存在',
    'Metric generation failed': '指标生成失败',
    'Custom screener creation exceeds the maximum limit': '自定义screener创建超过最大限制',
    'Screener filter fields cannot exceed 10': 'screener筛选字段不能超过10个',
    'Screener filter field cannot be duplicate': 'screener筛选字段不允许重复',
    'Parameter has non filterable fields': '参数存在不可筛选字段',
    'Incorrect filter operator': '筛选操作符不正确',
    'Grouping field error': '分组字段错误',
    'Sort field error': '排序字段错误',
    'Collation must exist': '排序规则必须存在',
    'Lock field or display field incoming error': '锁定字段或者展示字段传入错误',
    'Run up to 10 locked fields': '最多运行10个锁定字段',
    'Screener does not exist': 'screener不存在',
    'Project does not exist': '项目不存在',
    'Up to three levels of grouping are supported': '最多支持三级分组',
    'Grouping parameter cannot be empty': '分组字段不能为空',
    'Grouping parameters cannot be repeated': '分组字段不允许重复',
    'Wrong grouping parameter type': '分组字段类型错误',
    'Index keys cannot be empty': 'indexKeys不能为空',
    'Object keys cannot be empty': 'objectKeys不能为空',
    'Parameter cannot be empty': '参数不能为空',
    'URL cannot be empty': 'url不能为空',
    'Unsupported chain query': '不支持该链查询',
    'Failed to request BDS': '请求bds失败',
    'Failed to retrieve contract basic information, error requesting BDS': '获取合约基础信息失败, 请求bds出错',
    'Alert is unable to operate': '警报无法操作',
    'AlertNotice does not exist': '警报通知不存在',
    'AlertNotice conditions and projectList snapshot does not exist': '警报通知条件和项目列表快照不存在',
    'Conditions is not set correctly': '条件设置不正确',
    'Name must be shorter than or equal to 30 characters': '名称必须少于或等于30个字符',
    'Name cannot be pure space': '名称不能是纯空格',
    'Table config does not exist': '表格配置不存在',
    'TableId does not exist': '表格ID不存在',
    'Ground table mapping does not exist': '地面表映射不存在',
    'Group Field does not exist': '分组字段不存在',
    'Field formula does not exist': '字段公式不存在',
    'Event does not exist': '事件不存在',
    'Account info does not find': '账户信息未找到',
    'JournalEntry info does not find': '日记账信息未找到',
    'Update time does not find': '未找到更新时间',
    'Financial field does not exist': '财务字段不存在',
    'share failed': '共享失败',
    'Crypto payments to related parties - cash': '向关联方的加密货币支付-现金',
    'LiquidationCall - liquidate borrower': '清算调用-清算借款人',
    'LiquidationCall - repay interest': '清算调用-偿还利息',
    'LiquidationCall - repay principal': '清算调用-偿还本金',
    'Return to investors': '返还投资者',
    'Liquidity miner claim rewards': '流动性提供者领取奖励',
    'Staker claim rewards': '质押者领取奖励',
    'Swap liquidity': '流动性交换',
    'Commission expenses': '佣金支出',
    'Commission income': '佣金收入',
    'Withdrawals from customers': '用户提现',
    'Swap in from customers': '用户流入交换',
    'Swap out to customers': '用户流出交换',
    'Commission from flashswap': '闪电交换佣金',
    'Swap - token out': '交换 - 转出代币',
    'Swap - commission': '交换 - 佣金',
    'Swap - token in': '交换 - 转入代币',
    Collect: '收款',
    'Flash - liquidity': '闪电贷流动性',
    'Flash - principal in': '闪电贷本金流入',
    'Flash - principal out': '闪电贷本金流出',
    'Flash - commission': '闪电贷佣金',
    'Airdrop revenue': '空投收入',
    'Staking reward': '质押奖励',
    'Net investment income': '净投资收入',
    Operations: '运营',
    'Realized net gain/(loss)': '实现净收益/损失',
    'Change in unrealized appreciation/(depreciation)': '未实现升值/折旧变化',
    'Token flows from operating activities': '经营活动中的代币流量',
    'Swap in': '换入',
    'Swap out': '换出',
    'Claim staking reward': '领取质押奖励',
    'Crypto assets - cost': '加密资产 - 成本',
    'Crypto assets - fair value gain/(loss)': '加密资产 - 公允价值收益/损失',
    'Staked crypto assets': '质押的加密资产',
    'Intangible - NFTs': '无形资产 - NFT',
    'Net increase (decrease) in net assets resulting from operations': '由经营活动引起的净资产增减',
    Realization: '实现',
    'Realized cost': '实现成本',
    'Realized fair value gain/(loss)': '实现公允价值收益/损失',
    'Total realization': '总实现',
    'Paid-in': '已缴纳',
    'Total paid-in': '总已缴纳',
    'Net increase (decrease) in net assets resulting from financing': '由融资活动引起的净资产增减',
    'Total increase (decrease) in net assets': '净资产总增减',
    'Net assets': '净资产',
    'Beginning of period': '期初',
    'End of period': '期末',
    'Mark to market - crypto assets': '按市场价值计量的加密资产',
    'Airdrop token': '空投代币',
    Staking: '质押',
    'Net tokens from operating activities': '经营活动中的净代币流量',
    'Token flows from financing activities': '融资活动中的代币流量',
    'Provision for impairment - NFTs': 'NFT 减值准备',
    'Net assets for unit holders': '单元持有人的净资产',
    'Net tokens from financing activities': '融资活动中的净代币流量',
    'Net increase (decrease) in tokens': '代币净增减',
    'Tokens at the beginning of period': '期初代币',
    'Tokens at the end of period': '期末代币',
    'Staked crypto assets - fair value gain/(loss)': '锁定的加密资产 - 公允价值收益/(损失)',
    'Net invest income': '投资净收益',
    'Transaction costs paid': '支付的交易成本',
    'Realized - cost': '已实现 - 成本',
    'Realized - fair value gain/(loss)': '已实现 - 公允价值收益/(损失)',
    'Crypto assets voucher': '加密资产代金券',
    'Locked crypto assets - evervision': '锁定的加密资产-evervision',
    'Locked crypto assets - customer': '锁定的加密资产-客户',
    'Long-term investment': '长期投资',
    'Swap LP income': '流动性提供者收入',
    'Swap LP cost': '流动性提供者成本',
    'Other operating expenses': '其他营业支出',
    'Internal withdraw': '内部取款',
    'Sponsorship income received': '收到的赞助收入',
    'Loan to related parties': '向关联方发放的贷款',
    'Other operating income activities': '其他营业收入活动',
    'Other operating expenses activities': '其他营业支出活动',
    'Non-operating income activities': '非营业收入活动',
    'Non-operating expenses activities': '非营业支出活动',
    'Add liquidity to De-Fi protocols': '向De-Fi协议添加流动性',
    'Remove liquidity from De-Fi protocols': '从De-Fi协议中移除流动性',
    'Deposit to De-Fi protocols': '向De-Fi协议存款',
    'Withdraw from De-Fi protocols': '从De-Fi协议中提款',
    'Cash flows from investing activities': '投资活动现金流量',
    'Net cash used in investing activities': '投资活动产生的净现金流出',
    'Net cash used in financing activities': '筹资活动产生的净现金流出',
    'Sponsorship fee received': '收到的赞助费',
    'Remove liquidity to protocol': '向协议移除流动性',
    'Transaction fee spent': '交易手续费支出',
    'Withdraw from protocol': '从协议中提款',
    'Deposit on protocol': '在协议中存款',
    'Add liquidity to protocol': '向协议添加流动性',
    'Internal deposit': '内部存款',
    'Marketing campaign': '营销活动',
    'Customer swap in': '客户加入流动性池',
    'Customer swap out': '客户退出流动性池',
    'Deposited crypto assets': '充值加密资产',
    'Deposited crypto assets - everpay': '充值加密资产 - everpay',
    'Customer locked crypto assets': '客户锁定的加密资产',
    Borrowings: '借款',
    'Remove liquidity from protocol - principal': '从协议中移除流动性 - 本金',
    'Commission fee received': '收取的佣金费用',
    'Swap income as LP': '作为流动性提供者的交换收入',
    'Swap income as customer': '作为客户的交换收入',
    'LP reward': '流动性提供者奖励',
    'Swap cost as LP': '作为流动性提供者的交换成本',
    'Swap cost as customer': '作为客户的交换成本',
    'Swap with cex - in': '与中心化交易所的交换 - 转入',
    'Swap with protocol - in': '与协议的交换 - 转入',
    'Swap with cex - out': '与中心化交易所的交换 - 转出',
    'Swap with protocol - out': '与协议的交换 - 转出',
    'Misc expenses': '其他费用',
    'Remove liquidity from protocol - reward': '从协议中移除流动性 - 奖励',
    'Collect LP reward': '收集流动性提供者奖励',
    'Locked crypto assets': '锁定的加密资产',
    'Deposit to protocol': '充值到协议',
    'Swap with protocol': '协议交换',
    'Login failed 5 times, please try again in 30 minutes.': '登录失败5次，请在30分钟内重试。',

    Assets: '资产',
    'Cash and cash equivalents': '现金及现金等价物',
    'Crypto assets': '加密资产',
    'Unrestricted crypto assets': '未受限加密资产',
    'Restricted crypto assets': '受限制的加密资产',
    'Crypto assets restricted by staking protocols': '受到质押合约限制的加密资产',
    'Crypto assets restricted by liquidity providing protoclos': '受到流动性合约限制的加密资产',
    'Crypto assets restricted by farming protocols': '受到挖矿合约限制的加密资产',
    'Crypto assets restricted by lender': '受到借贷合约限制的加密资产',
    'Crypto assets restricted by custodians': '受到托管合约限制的加密资产',
    'Crypto assets restricted by locking protocols': '受到锁定合约限制的加密资产',
    Receivables: '应收项',
    'Crypto receivables': '应收加密资产',
    'Staking rewards receivalbe': '应收质押奖励',
    'LP staking rewards receivalbe': '应收流动性质押奖励',
    'Farming rewards receivalbe': '应收挖矿奖励',
    'Locking rewards receivalbe': '应收锁定奖励',
    'Interest receivable': '应收利息',
    'Dividends receivable': '应收股利',
    'Loan receivable': '应收贷款',
    'Accounts receivalbe': '应收账款',
    'Other receivables': '其他应收项',
    Inventory: '存货',
    'Prepaid expenses': '预付费用',
    Investments: '投资',
    'Pre-ICOs': 'Pre-ICOs',
    'Long-term Investments': '长期投资',
    'Property, plant and equipment': '固定资产',
    'NFTs, net': 'NFTs, net',
    NFTs: 'NFTs',
    'Accumulated impairment - NFTs': '累计减值 - NFTs',
    Liabilities: '负债',
    Payables: '应付项',
    'Crypto payables': '应付加密资产',
    'Accounts payable': '应付账款',
    'Dividends payable': '应付股利',
    'Interest payable': '应付利息',
    'Other payables': '其他应收项',
    'Accrued expenses': '应计费用',
    'Financial liabilities': '金融负债',
    'Crypto borrowings': '加密货币借款',
    'Deposit from customers': '用户存款',
    'Customer crypto liabilities': '保管用户资产负债',
    'Other debts and borrowings': '其他负债',
    Equity: '权益',
    "Owner's equity ": '所有者权益',
    'Retained earnings': '留存收益',
    Revenue: '收入',
    Revenues: '收入',
    'Operating income': '营业收入',
    'NFTs primary sales': 'NFTs 初次销售收入',
    'NFTs secondary sales': 'NFTs 二次销售收入',
    'NFTs royalties': 'NFTs 版税收入',
    'Commissions income': '佣金收入',
    'DAO incentive income': 'DAO激励收入',
    'Sponsorship income': '赞助收入',
    'Mint income': 'Mint 收入',
    'Funding income': '资金费收入',
    'Other operating income': '其他营业收入',
    Expenses: '费用',
    'Cost of sales': '销售成本',
    'Cost of NFTs': 'NFTs 销售成本',
    'Cost of NFTs - royalties': 'NFTs 销售成本 - 版税费用',
    'Other cost of sales': '其他销售成本',
    'General and administrative expenses': '管理费用',
    'Payroll and staff related costs': '员工工资',
    'Research and development': '研发费用',
    'Software & web services': '软件和网络服务费用',
    'Miscellaneous operating expenses': '其他营业支出',
    'Marketing expense': '营销费用',
    'Transaction cost': '交易费用',
    'Impairment cost': '减值费用',
    'Impairment cost - NFTs': '减值费用 - NFTs',
    'Impairment cost - Long-term investments': '减值费用 - 长期投资',
    'Royalties cost': '版税费用',
    'Funding fee': '资金费费用',
    'Other income and expenses': '其他收入和费用',
    'Other income': '其他收入',
    'Financial income': '金融收入',
    'Interest income': '利息收入',
    'Dividends income': '股利收入',
    'Staking rewards': '质押奖励',
    'LP staking rewards': '流动性质押奖励',
    'Farming rewards': '挖矿奖励',
    'Locking rewards': '锁定奖励',
    'Other investment income': '其他投资收入',
    'Hard fork': '硬分叉收入',
    'Grant income': '津贴收入',
    'Other miscellaneous income': '其他收入',
    'Other expenses': '其他费用',
    'Financial expenses': '财务费用',
    'Interest expense': '利息费用',
    hacked: '黑客攻击',
    'Other finance expenses': '其他财务费用',
    'lost/stolen': '丢失/被盗',
    'Other miscellaneous expenses': '其他费用',
    'Gains And Losses': '损益',
    'Gain (Loss) on crypto activities': '加密资产业务活动导致的损益',
    'Fiat exchange gain (loss)': '法定货币兑换损益',
    'Other gains (Losses) ': '其他损益',
    'Unrealized gain (loss) - crypto fair value measurement': '未实现损益 - 加密资产公允价值计量',
    'Other unrealized gains and (losses)': '其他未实现损益',
    'Gain (Loss) on investments': '投资损益',
    'Gain (Loss) from customer orders': '用户做单带来的损益',
    'NFTs royalties income': 'NFTs 版税收入',
    'Purchase NFTs': '购买 NFTs',
    'Mint NFTs': '铸造 NFTs',
    'Pay NFTs royalties': '支付 NFTs 版税',
    Payroll: '员工工资支出',
    'Misc.expenses': '其他费用支出',
    'Customer deposit funds': '用户入金',
    'Customer withdraw funds': '用户出金',
    'Gain from customer orders': '用户做单盈利',
    'Loss from customer orders': '用户做单亏损',
    'Unrealized gain from customer orders': '用户做单盈利（已实现）',
    'Unrealized loss from customer orders': '用户做单盈利（未实现）',
    Airdrop: '空投收入',
    'Hard folk': '硬分叉',
    'Chain split': '链分裂收入',
    Grants: '津贴收入',
    'Misc. revenue': '其他收入',
    'Transaction fee': '交易费用',
    'Marketing expenses': '营销费用',
    'Commission fee': '佣金费用',
    'Lost/Stolen': '丢失/被盗',
    'Other expense': '其他费用',
    Borrowing: '借款',
    'Repay borrow': '还款',
    'Pay interest expense': '支付利息',
    'Investment from shareholders': '投资人打款',
    'Dividends to shareholders': '支付投资人股利',
    'Shareholders exit': '投资人退出',
    'Buy token': '购买加密货币',
    'Cashback received': '收到返现',
    'Sell token': '销售加密货币',
    'Swap-in': '货币兑换-买入',
    'Swap-out': '货币兑换-卖出',
    'Staking deposit': '质押',
    Unstaking: '解除质押',
    'Receive staking rewards': '收到质押奖励',
    'Add liquidity': '增加流动性',
    'Remove liquidity': '抽取流动性',
    'Receive LP staking rewards': '收到流动性质押奖励',
    'Farming deposit': '挖矿质押',
    'Remove farming': '解除挖矿质押',
    'Receive farming rewards': '收到挖矿奖励',
    'Lending deposit': '向借贷协议存款',
    'Lending redeem': '从借贷协议提款',
    'Receive interest income': '收到利息收入',
    'Custody deposit': '托管存款',
    'Withdraw custody': '托管取款',
    'Receive custody income': '收到托管收入',
    'Locking deposit': '锁定',
    Unlock: '解锁',
    'Receive locking rewards': '收到锁定奖励',
    'Long-term equity investment': '长期股权投资',
    'Exit long-term equity investment': '退出长期股权投资',
    'Receive dividends': '收到股利',
    'Pre-ICO investments': 'Pre-ICO 投资',
    'Investee ICO': '被投方 ICO',
    'Unlock ICO crypto assets': '解锁 ICO 资产',
    'Loan to customers': '向客户发放贷款',
    'Customer repayment': '客户归还贷款',
    'Receive loan interest': '收到贷款利息',
    'Customer swap-out': '客户货币兑换-卖出',
    'Customer swap-in': '客户货币兑换-买入',
    'Customer deposit': '用户存款',
    'Customer withdraw': '用户取款',
    'Pay interest to customers': '向用户支付利息',
    'Purchase fixed assets': '购买固定资产',
    'Other investment': '其他投资',
    'Exit other investment': '退出其他投资',
    'Receive other investment income': '收到其他投资收入',
    'Internal transfer': '内部交易',
    Ignore: '忽略',
    'Adjusting for unrealized gain/loss': '未实现损益调账',
    'Adjusting for realized gain/loss': '已实现损益调账',
    'Mark to market - Fiat assets': '公允价值调整-法币资产',
    'Mark to market - Fiat liabilities': '公允价值调整-法币负债',
    'Income from operations': '净营业收入',
    'Net gain/loss from crypto activities': '加密活动带来的净损益',
    'Other gains/losses': '其他损益',
    'Total non-operating or other': '营业外净收入',
    'Net income': '净利润',
    "Owner's equity": '所有者权益',
    'Sales of goods or services': '商品或服务销售收入',
    'Other operating cash receipts and payments': '其他经营性现金收支',
    'Net cash from operating activities': '经营活动产生的净现金流量',
    'Proceeds from issuance of debt': '债务发行所得款项',
    'Issuance of common stock': '股权融资所得款项',
    'Proceeds from disposal of crypto assets': '加密资产处置所得款项',
    'Total restricted crypto assets': '受限制的加密资产总额',
    'Total crypto assets': '加密资产总额',
    'Total fair value at the beginning of period': '期初公允价值总额',
    'Assets acquired': '获得的资产',
    'Purchases of crypto assets': '购买的加密资产',
    'Proceeds from settlement of investments': '投资结算所得款项',
    'Income received': '收到的收入金额',
    'Total assets acquired': '已获得的加密资产总额',
    'Assets disposed': '处置的资产 ',
    'Sales of crypto assets': '出售的加密资产 ',
    'Payment usage': '用于支付用途的加密资产',
    'Repayments of debt': '偿还债务',
    'Payments of dividends': '支付分红',
    'Purchases of investments': '购买投资产品',
    'Total assets disposed': '已处置的加密资产总额',
    'Gains(Realized and Unrealized)': '盈利（实现与未实现总和）  ',
    'Losses(Realized and Unrealized)': '亏损（实现与未实现总和）  ',
    'Total Fair Value at the end of period': '期末公允价值总额',
    'Realized vs. unrealized gains and losses': '已实现盈亏与未实现盈亏比较表',
    'DAO incentives': 'DAO激励收入',
    'Sponsorship revenue': '赞助收入',
    'Impairments - NFTs': 'NFTs 减值损失',
    'Lost/stolen': '丢失/被盗',
    'Community marketing expenses': '市场推广费用',
    'Other gains (Losses)': '其他损益',
    'Unrealized gain (loss) - customer orders': '未实现损益 - 用户做单',
    Operating: '经营活动',
    Financing: '融资',
    Investing: '投资',
    'Customer assets swap in': '用户资产货币兑换-买入',
    'Customer assets swap out': '用户资产货币兑换-卖出',
    'Adjusting for unrealized gain/loss - assets': '未实现损益调账-资产',
    'Adjusting for realized gain/loss - assets': '已实现损益调账-资产',
    'Adjusting for realized gain/loss - liabilities': '已实现损益调账-负债',
    'Adjusting for unrealized gain/loss - liabilities': '未实现损益调账-负债',
    'Customer loss': '用户做单亏损',
    'Customer gain': '用户做单盈利',
    'Customer unrealized loss': '用户做单盈利（未实现）',
    'Customer unrealized gain': '用户做单盈利（已实现）',
    'Fiat exchange in': '美元兑出',
    'Fiat exchange out': '美元兑入',
    'payments of marketing expenses': '支付营销费用',
    'Payroll - prepaid amortization': '员工工资 - 预付费用结转',
    'Research and development - prepaid amortization': '研发费用支出 - 预付费用结转',
    'Software & web services - prepaid amortization': '软件和网络服务支出',
    'Misc.expenses  - prepaid amortization': '其他费用支出',
    'Marketing expenses  - prepaid amortization': '营销费用',
    'Commission fee  - prepaid amortization': '佣金费用',
    'Other expense  - prepaid amortization': '其他费用'
  }
}
