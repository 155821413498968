/* eslint-disable no-unused-vars */
/* eslint-disable no-lonely-if */
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
import _ from 'lodash-es'
import * as XLSX from 'xlsx'
import millify from 'millify'
import languages from '@/config/languages'
import { reportEventList } from '@/config/reports'
import { EnumListValueType } from '#/GlobalConfigTypes'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { useGlobalStore } from '@/stores/modules/global'

const utils = {
  /**
   * 格式化时间,传入不同的type，返回不同的类型
   *
   * @param  {value:time,type: 'full', 'base','date','word','wordtwo', 'time','longAgo', 'briefLongAgo','briefTime', interval:'/','-'}
   * @return
   *  full: '2019/01/01 12:12:12'
   *  base: '2019/01/01 12:12'
   *  date: '2019/01/01'
   *  number: '20190101'
   *  time: '12:12'
   *  word: '2019年01月01日 12：12'
   *  wordDate: '2019年01月01日'
   *  wordtwo: '01月01日 12：12'
   *  wordtwoTo: '01-01 12：12'
   *  longAgo: '几分钟前 2019/01/01 12:12'
   *  briefLongAgo: '几分钟前 01-01 12:12'
   *  briefTime:"12:12  01/01 12:12  2019/01/01"
   *  MM-DD: '12-11'
   * @example
   */
  dateFormatting(value: any, type = 'base', interval = '/') {
    const dateValue = new Date(value)
    const now = new Date().getTime()
    let oldTime
    let result
    const minute = 1000 * 60
    const hour = minute * 60
    const day = hour * 24
    const year = day * 365

    if (typeof value === 'string' && value.indexOf('-') > 0) {
      value = value.replace(/-/g, '/')
      oldTime = new Date(dateValue).getTime()
    } else {
      oldTime = value * 1000
    }

    // 获取详细时间
    const myDate = new Date(oldTime)
    const Y = myDate.getFullYear()
    const M = myDate.getMonth() + 1 < 10 ? `0${myDate.getMonth() + 1}` : myDate.getMonth() + 1
    const D = myDate.getDate() < 10 ? `0${myDate.getDate()}` : myDate.getDate()
    const h = myDate.getHours() < 10 ? `0${myDate.getHours()}` : myDate.getHours()
    const m = myDate.getMinutes() < 10 ? `0${myDate.getMinutes()}` : myDate.getMinutes()
    const s = myDate.getSeconds() < 10 ? `0${myDate.getSeconds()}` : myDate.getSeconds()
    const fullDate = `${Y + interval + M + interval + D} ${h}:${m}:${s}`
    const baseDate = `${M + interval + D} ${h}:${m}`
    const detailDate = `${Y + interval + M + interval + D} ${h}:${m}`
    const numberDate = `${`${Y}${M}${D}`}`
    const briefDetailDate = `${M + interval + D} ${h}:${m}`
    const wordDetailDate = `${Y}年${M}月${D}日 ${h}:${m}`
    const wordDate = `${Y}年${M}月${D}日 `
    const wordDetailDateTwo = `${M}月${D}日 ${h}:${m}`
    const wordtwoToDetailDateTwo = `${M}-${D} ${h}:${m}`
    // 时间差
    // eslint-disable-next-line prefer-const
    const difference = now - oldTime
    const _year = difference / year
    const _day = difference / day
    const _hour = difference / hour
    const _min = difference / minute

    switch (type) {
      case 'full':
        result = fullDate
        break
      case 'baseDate':
        result = baseDate
        break
      case 'base':
        result = detailDate
        break
      case 'number':
        result = numberDate
        break
      case 'word':
        result = wordDetailDate
        break
      case 'wordDate':
        result = wordDate
        break
      case 'wordtwo':
        result = wordDetailDateTwo
        break
      case 'wordtwoTo':
        result = wordtwoToDetailDateTwo
        break

      case 'date':
        result = Y + interval + M + interval + D
        break
      case 'time':
        result = `${h}:${m}`
        break
      case 'longAgo':
        // 超过24小时        显示  年/月/日 时:分
        // 24小时之内        显示  XX小时前
        // 60分钟之内        显示  XX分钟前
        if (_day >= 1) {
          result = detailDate
        } else if (_hour >= 1) {
          result = `${~~_hour} 小时前`
        } else if (_min >= 1) {
          result = `${~~_min} 分钟前`
        } else {
          result = '刚刚'
        }
        break
      case 'briefLongAgo':
        // 超过24小时        显示  月-日 时:分
        // 24小时之内        显示  XX小时前
        // 60分钟之内        显示  XX分钟前
        if (_day >= 1) {
          result = briefDetailDate
        } else if (_hour >= 1) {
          result = `${~~_hour} 小时前`
        } else if (_min >= 1) {
          result = `${~~_min} 分钟前`
        } else {
          result = '刚刚'
        }
        break
      case 'briefTime':
        // 超过一年           显示 年-月-日
        // 超过24小时        显示  月-日 时:分
        // 24小时之内        显示  时:分

        if (_year >= 1) {
          result = Y + interval + M + interval + D
        } else if (_day >= 1) {
          result = briefDetailDate
        } else {
          result = `${h}:${m}`
        }
        break
      case 'MM-DD':
        result = M + interval + D
        break
      case 'MM--DD':
        result = `${M}-${D}`
        break
      case 'DD':
        result = D
        break
      default:
        result = detailDate
        break
    }
    return result
  },

  /**
   *  中间用...展示
   * val: 需要处理的值
   * start: 保留的前面字数(默认：4)
   * end: 保留的后面是字数(默认：4)
   * sign: 中间替换成的内容(默认：...)
   */
  middleEllipsis(val: string, start = 4, end = -4, sign = '...') {
    return val.slice(0, start) + sign + val.slice(end)
  },

  /**
   * 数字千分位展示
   * @param num 展示的数
   * @param precision 保留小数点位数
   * @param separator 标志
   * @returns {*}
   *=======================================================
   *     formatNumber(10000)="10,000"
   *     formatNumber(10000, 2)="10,000.00"
   *     formatNumber(10000.123456, 2)="10,000.12"
   *     formatNumber(10000.123456, 2, ' ')="10 000.12"
   *     formatNumber(.123456, 2, ' ')="0.12"
   *     formatNumber(56., 2, ' ')="56.00"
   *     formatNumber(56., 0, ' ')="56"
   *     formatNumber('56.')="56"
   *     formatNumber('56.a')=NaN
   *=======================================================
   */
  formatNumber(num: any, precision = 0, separator = ',') {
    let parts
    // 判断是否为数字
    if (!Number.isNaN(parseFloat(num)) && Number.isFinite(num)) {
      // 把类似 .5, 5. 之类的数据转化成0.5, 5, 为数据精度处理做准, 至于为什么
      // 不在判断中直接写 if (!Number.isNaN(num = parseFloat(num)) && Number.isFinite(num))
      // 是因为parseFloat有一个奇怪的精度问题, 比如 parseFloat(12312312.1234567119)
      // 的值变成了 12312312.123456713
      num = Number(num)
      // 处理小数点位数
      num = (typeof precision !== 'undefined' ? num.toFixed(precision) : num).toString()
      // 分离数字的小数部分和整数部分
      parts = num.split('.')
      // 整数部分加[separator]分隔, 借用一个著名的正则表达式
      parts[0] = parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator || ','}`)
      return parts.join('.')
    }
    // return NaN;
    return num
  },
  formatDecimal(num: any, decimal: number) {
    num = num.toString()
    const index = num.indexOf('.')
    if (index !== -1) {
      num = num.substring(0, decimal + index + 1)
    } else {
      num = num.substring(0)
    }
    return `${parseFloat(num)}%`
  },
  /**
   * 数字百分号展示
   * @param num 展示的数
   * @param precision 展示%前面保留小数点位数
   * @returns {*}
   *=======================================================
   *     formatNumber(912.3456, 2)="9,1234.56%"
   *     formatNumber(0.123456)="12%"
   *     formatNumber(0.123456,2)="12.35%"
   *     formatNumber(0)="0"
   *=======================================================
   */
  toPercent(num: any, precision = 0) {
    let parts
    // 判断是否为数字
    if (!Number.isNaN(parseFloat(num)) && Number.isFinite(num)) {
      if (num === 0) {
        return 0
      }
      num = Number(num) * 100
      // 处理小数点位数
      num = (typeof precision !== 'undefined' ? num.toFixed(precision) : num).toString()
      // 分离数字的小数部分和整数部分
      parts = num.split('.')
      // 整数部分加[separator]分隔, 借用一个著名的正则表达式
      // eslint-disable-next-line no-useless-concat
      parts[0] = parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + ',')
      // 拼接百分号
      return `${parts.join('.')}%`
    }
    return num
  },
  formatBigNumber(number: any) {
    return millify(Number(number), {
      precision: 1,
      lowercase: false
    })
  },
  // 格式化占比

  formatProportion(list: Array<any>, transactionNum: number) {
    const transactionCounts = list.map((item: any) => Number(item.transactionCount))
    const transactionSum = _.sum(transactionCounts)
    return this.formatDecimal((transactionNum / transactionSum) * 100, 1)
  },
  // 格式化输入地址
  formatInputAddress(address: string, prefixLength: number = 3, suffixLength: number = -4) {
    if (_.isEmpty(address)) {
      return ''
    }

    return `${address.substr(0, prefixLength)}...${address.substr(suffixLength)}`
  },
  // 字符串超出一定位数,用...代替
  // str 具体的某个字符串 num 要截取多少位
  formatString(str: string, num: number) {
    if (str.length > num) {
      // 如果字符长度超过10，后面的字符就变成...可自行调整长度和代替字符
      str = `${str.substr(0, num)}...` // 截取从第一个字符开始，往后取10个字符，剩余的用...代替
      return str
    }
    return str
  },
  // 判断一个数组是否包含了另一个数组的全部元素
  isContained(firstArray: Array<any>, secondArray: Array<any>) {
    // firstArray和secondArray其中一个不是数组，直接返回false
    if (!(firstArray instanceof Array) || !(secondArray instanceof Array)) return false
    const len = secondArray.length
    // firstArray的长度小于secondArray的长度，直接返回false
    if (firstArray.length < len) return false
    for (let i = 0; i < len; i += 1) {
      // 遍历secondArray中的元素，遇到firstArray没有包含某个元素的，直接返回false
      if (!firstArray.includes(secondArray[i])) return false
    }
    // 遍历结束，返回true
    return true
  },
  // 保留n位小数同时增加单位k m b...
  formatThousandthAndUnit(number: any, precision: number) {
    const formatNumber = Number(number)
    if (_.isNaN(formatNumber)) {
      return ''
    }

    return millify(formatNumber, {
      precision,
      lowercase: true
    })
  },
  // 保留n位小数同时增加单位k m b... 如果是k保留1位
  formatThousandthAndUnitWithOnlyK(number: any, precision: number) {
    if (number >= 1000 && number <= 9999) {
      return millify(Number(number), {
        precision: 1,
        lowercase: true
      })
    }
    return millify(Number(number), {
      precision,
      lowercase: true
    })
  },

  // 表格格式化方法
  // @params data 数据值
  // @params config{''|dollar|price|percent}
  // @params type 类型   number

  // 根据type类型 number
  // 根据config
  // percent :单位为【%】保留小数点之后2位，不进行四舍五入，小数点前每3位一个【,】分隔
  // 为空时:保留小数点之后1位，小数点前每3位一个【,】分隔
  // dollar:true  单位为【$】,保留小数点之后1位，小数点前每3位一个【,】分隔
  // price:true  单位为【$】，小数点前每3位一个【,】分隔。不增加K等度量单位，展示实际的数字}
  fieldValueFormat(data: number | null, config: any, type: string) {
    if (data == null) {
      return '-'
    }

    if (type === 'NUMBER') {
      if (config.percent) {
        return this.toPercent(data, 2)
      }
      if (config.dollar) {
        const formatDollarNumber = this.formatThousandthAndUnit(Number(data) < 0 ? Math.abs(Number(data)) : data, 1)
        return Number(data) < 0 ? `-$${formatDollarNumber}` : `$${formatDollarNumber}`
      }
      if (config.price) {
        // 0
        if (data === 0) {
          return `$${data}`
        }
        // 0 -> 1
        if (data > 0 && data < 1) {
          return `$${Number(data).toPrecision(4)}`
        }
        //  < 0
        if (data < 0) {
          return `-$${Math.abs(Number(data)).toPrecision(4)}`
        }
        //  > 1
        return `$${this.formatThousandthAndUnit(data, 2)}`
      }
      if (_.isEmpty(config)) {
        return this.formatThousandthAndUnit(data, 1)
      }
    }

    return this.formatThousandthAndUnit(data, 1)
  },

  chartValueFormat(data: number | null, config: any) {
    if (data == null) {
      return '-'
    }

    if (config.percent) {
      return this.toPercent(data, 2)
    }
    if (config.dollar) {
      return `$${this.formatNumber(data)}`
    }
    if (config.price) {
      // 0
      if (data === 0) {
        return `$${data}`
      }
      // 0 -> 1
      if (data > 0 && data < 1) {
        return `$${Number(data).toPrecision(4)}`
      }
      //  < 0
      if (data < 0) {
        return `-$${Math.abs(Number(data)).toPrecision(4)}`
      }
      //  > 1
      return `$${this.formatNumber(data)}`
    }
    if (!config) {
      return this.formatNumber(data)
    }

    return this.formatNumber(data)
  },

  chartYAxisformat(data: number | null, config: any) {
    if (data == null) {
      return ''
    }
    if (config.percent) {
      return this.toPercent(data, 2)
    }
    if (config.price || config.dollar) {
      return millify(Number(data), {
        precision: 2,
        lowercase: true
      })
    }
    return millify(Number(data), {
      precision: 0,
      lowercase: true
    })
  },
  formatEnum(enumList: any, allEnumList: any) {
    const tempCategorys = allEnumList.filter((enumItem: EnumListValueType) => _.includes(enumList, enumItem.enumId))

    return tempCategorys.map((enumItem: EnumListValueType) => enumItem.name).join('/')
  },
  /**
   * 合并相同数据，导出合并列所需的方法(只适合el-table)
   * @param {Object} data
   * @param {Object} rowSpanArray
   */
  getRowSpanMethod(data: any, rowSpanArray: any) {
    /**
     * 要合并列的数据
     */
    const rowSpanNumObject: any = {}

    // 初始化 rowSpanNumObject
    rowSpanArray.map((item: any) => {
      rowSpanNumObject[item] = new Array(data.length).fill(1, 0, 1).fill(0, 1)
      rowSpanNumObject[`${item}-index`] = 0
      return true
    })
    // 计算相关的合并信息
    for (let i = 1; i < data.length; i += 1) {
      rowSpanArray.map((key: any) => {
        const index = rowSpanNumObject[`${key}-index`]
        if (data[i][key] === data[i - 1][key]) {
          rowSpanNumObject[key][index] += 1
        } else {
          rowSpanNumObject[`${key}-index`] = i
          rowSpanNumObject[key][i] = 1
        }
        return true
      })
    }

    // 提供合并的方法并导出
    // eslint-disable-next-line no-unused-vars
    const spanMethod = ({ row, column, rowIndex, columnIndex }: any) => {
      if (rowSpanArray.includes(column.property)) {
        const rowspan = rowSpanNumObject[column.property][rowIndex]
        if (rowspan > 0) {
          return { rowspan, colspan: 1 }
        }
        return { rowspan: 0, colspan: 0 }
      }
      return { rowspan: 1, colspan: 1 }
    }

    return spanMethod
  },
  /* ,
   * 字符串长度验证, 中文算一个，英文算半个
   * @param str 字符串
   */ validatStrLength(str: any) {
    let bytesCount = 0
    for (let i = 0, n = str.length; i < n; i += 1) {
      const c = str.charCodeAt(i)
      if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
        bytesCount += 1
      } else {
        bytesCount += 2
      }
    }
    const length = (bytesCount / 2).toFixed(0)
    return Number(length)
  },

  // 负数添加（,）显示，例如：-300=>(300）,-3000=>(3,000)
  // unit 单位 thousands，millions，billions, individuals
  // separator 为不是数字时的替换符号
  // 当值是0时，需要用替换符号代替吗？-默认不代替
  // balanceType,默认“Dr“，根据科目的balanceType, Dr 不加括号, Cr 0 加括号
  numDrCrHandle(
    unit: any,
    num: any,
    balanceType: string = 'Dr',
    separator: any = '-',
    isSeparatorZero: boolean = false,
    isUnit: boolean = true
  ) {
    let finVal
    let absVal
    // eslint-disable-next-line no-shadow
    enum UnitEnum {
      individuals = 1,
      thousands = 1000,
      millions = 1000000,
      billions = 1000000000
    }
    const unitNumber = Number(UnitEnum[unit])
    // 是数字时
    if (typeof num === 'number') {
      absVal = isUnit ? Math.abs(num) / unitNumber : Math.abs(num)
      if (num > 0) {
        finVal = this.formatNumber(absVal, 0)
      } else if (num < 0) {
        finVal = `( ${this.formatNumber(absVal, 0)} )`
      } else if (num === 0) {
        if (isSeparatorZero) {
          finVal = separator
        } else {
          if (balanceType === 'Cr') {
            finVal = '( 0 )'
          } else if (balanceType === 'Dr') {
            finVal = 0
          } else {
            finVal = 0
          }
        }
      }
      // 不是是数字时，用替换符号代替
    } else {
      finVal = separator
    }
    return finVal
  },
  /**
   * 添加Meta标签
   * @param {string} name
   * @param {string} content
   * @param {string} property
   * @returns
   */
  addMeta(content: string, name: string = '', property: string = '') {
    const meta: any = document.createElement('meta')
    if (name !== '') {
      meta.name = name
    }
    if (property !== '') {
      meta.id = 'Financials-Share'
      meta.setAttribute('property', property)
    }
    meta.content = content
    document.getElementsByTagName('head')[0].appendChild(meta)
  },

  /**
   * 判断是否是为移动端
   */
  isMobile() {
    return /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(
      navigator.userAgent
    )
  },
  /**
   * 判断是否是为ios环境
   */
  isIOS() {
    const u: string = navigator.userAgent
    return u.toLowerCase().indexOf('iphone') >= 0 || u.toLowerCase().indexOf('ipad') >= 0 // ios终端
  },
  /**
   * 文本国际化
   * @param {string} text
   */
  formatLanguageContent(text: string) {
    const language = localStorage.getItem('language') ?? 'en'
    if (language === 'en') return text
    const languageData = _.result(languages, language)
    if (_.isEmpty(languageData)) return text
    const languageText = _.result(languageData, String(text).trim())
    if (!languageText) return text
    return languageText
  },
  objToQueryStr(params: Object) {
    return Object.entries(params)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
  },
  async getUuid() {
    // Initialize an agent at application startup.
    const fpPromise = FingerprintJS.load()

    // Get the visitor identifier when you need it.
    const fp = await fpPromise
    const result = await fp.get()

    return result.visitorId
  },

  // 导出xlsx
  exportExcel(transactionList: [], fileName = '交易表.xlsx') {
    const sheetData1List = _.map(transactionList, (item: any) => {
      return {
        'Datetime(UTC)': this.dateFormatting(item.datetime),
        Source: item.source?.sourceName,
        // eslint-disable-next-line no-nested-ternary
        Direction: item?.direction === 'OUT' ? 'Outflow' : item?.direction === 'IN' ? 'Inflow' : 'Internal transfer',
        Event: _.find(reportEventList, { categoryNo: item.categoryNo })?.categoryName,
        Currency: item?.currency,
        Amount: item?.amount,
        'Amount(USD)': item?.amountUsd,
        From: item?.fromAddress,
        To: item?.toAddress,
        'TxHash/TxID': item?.txHash
      }
    })
    const sheet1 = XLSX.utils.json_to_sheet(sheetData1List, { cellDates: true })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, sheet1, 'transaction')
    // 导出 Excel
    XLSX.writeFile(wb, fileName)
  },
  queryStrToObj(queryStr: string) {
    const queryObj: any = {}
    // queryStr截取?后的参数转为对象
    if (queryStr.indexOf('?') !== -1) {
      const str = queryStr.substr(1)
      const strs = str.split('&')
      for (let i = 0; i < strs.length; i += 1) {
        queryObj[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1])
      }
    } else if (queryStr) {
      const queryArr = queryStr.split('&')
      queryArr.forEach((item) => {
        const tempArr = item.split('=')
        // eslint-disable-next-line prefer-destructuring
        queryObj[tempArr[0]] = tempArr[1]
      })
    }
    return queryObj
  }
}

export default utils
