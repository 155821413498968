<template>
  <el-dialog
    v-model="showSwitchVersionDialog"
    width="460px"
    align-center
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-switch-version-dialog"
  >
    <template #header="{ titleId }"
      ><img :id="titleId" src="@/assets/img/project/switch_version_header.png" alt="switch_version_header"
    /></template>
    <h5>{{ t('title.introducingElvenV3') }}</h5>
    <p>{{ t('message.introducingElvenV3Info') }}</p>
    <elv-button width="200" height="42" @click="onSwitchVersion('v3')">{{ t('button.switchToV3') }}</elv-button>
    <p @click="onSwitchVersion('v2')">{{ t('button.continueToUseV2') }}</p>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useCookies } from 'vue3-cookies'

const props = defineProps({
  userId: {
    type: String,
    default: ''
  }
})

const { t } = useI18n()
const { cookies } = useCookies()

const showSwitchVersionDialog = ref(true)

// 切换弹窗展示
const onCheckReviewTeachDialog = () => {
  showSwitchVersionDialog.value = !showSwitchVersionDialog.value
}

const onSwitchVersion = (version: string) => {
  cookies.set(
    `elv-app-v2Used-${props.userId}`,
    version === 'v3' ? 'false' : 'true',
    '3y',
    '/',
    import.meta.env.VITE_APP_COOKIES_DOMAIN,
    true
  )
  cookies.set('elv-app-version', version, '3y', '/', import.meta.env.VITE_APP_COOKIES_DOMAIN, true)
  if (version === 'v3') {
    window.open(`${import.meta.env.VITE_APP_VERSION_URL}`, '_self')
  }
  showSwitchVersionDialog.value = false
}

defineExpose({ onCheckReviewTeachDialog })
</script>

<style lang="scss">
.elv-switch-version-dialog {
  height: 384px;
  border-radius: 20px !important;
  background: #fff !important;
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.15), 0px 0px 2px 0px rgba(0, 0, 0, 0.08) !important;

  .el-dialog__header {
    width: 460px;
    height: 150px;
    margin: 0;
    padding: 0;
    border: 0px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

    img {
      width: 100%;
      height: 100%;
      display: block;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    }
  }

  .el-dialog__body {
    display: flex;
    padding: 24px 0px;
    flex-direction: column;
    align-items: center;
    height: 234px;
    box-sizing: border-box;
    align-self: stretch;
    font-family: 'Plus Jakarta Sans';

    h5 {
      color: #0e0f11;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 8px;
    }

    p {
      color: #636b75;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 17px;
      margin-bottom: 24px;
      width: 380px;

      &:last-of-type {
        margin-bottom: 0;
        text-align: center;
        cursor: pointer;
        width: 200px;
      }
    }

    button {
      margin-bottom: 16px;
    }
  }
}
</style>
