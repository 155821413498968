import axios from 'axios'

interface IntervalType {
  timer: number | null
  setInterval: Function
  clearInterval: Function
}

const Interval: IntervalType = {
  timer: null,
  setInterval(callback: Function, interval: number) {
    let startTime = new Date().valueOf()
    let endTime = new Date().valueOf()
    const loop = () => {
      this.timer = requestAnimationFrame(loop)
      endTime = new Date().valueOf()
      if (endTime - startTime >= interval) {
        // eslint-disable-next-line no-multi-assign
        endTime = startTime = new Date().valueOf()
        // eslint-disable-next-line no-unused-expressions
        callback && callback()
      }
    }
    this.timer = requestAnimationFrame(loop)
    return this.timer
  },
  clearInterval() {
    cancelAnimationFrame(this.timer as number)
  }
}

export function getUpdateVersion() {
  if (process.env.VITE__APP_COMMAND__ === 'serve') return
  Interval.setInterval(async () => {
    const response = await axios.get(`${window.location.origin}/version.json`)
    localStorage.setItem('UPDATE_VERSION', response.data.version)
  }, 1000 * 10)
}

export default getUpdateVersion
