import { http } from '@/lib/http'
import { ConfigType } from '#/GlobalConfigTypes'

export default {
  /**
   * 获取配置
   */
  getGlobalConfig: () => {
    return http.request<ResponseData<ConfigType>>('get', `/config`)
  }
}
