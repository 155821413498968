<template>
  <el-button
    class="elv-button"
    type="primary"
    :loading="props.loading"
    :disabled="props.disabled"
    :round="props.round"
    :plain="props.plain"
  >
    <template v-if="props.plain" #loading>
      <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" class="elv-button__loading" />
    </template>
    <slot></slot>
  </el-button>
</template>

<script setup lang="ts">
const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  width: {
    type: [String, Number],
    default: ''
  },
  height: {
    type: [String, Number],
    default: '36'
  },
  round: {
    type: Boolean,
    default: false
  },
  plain: {
    type: Boolean,
    default: false
  }
})

const buttonWidth = computed(() => {
  if (!props.width) return 'auto'
  if (!Number(props.width)) return props.width
  return `${props.width}px`
})

const buttonHeight = computed(() => {
  if (!Number(props.height)) return props.height
  return `${props.height}px`
})
</script>

<style lang="scss" scoped>
.elv-button {
  width: v-bind('buttonWidth');
  height: v-bind('buttonHeight');
  background: #1753eb;
  box-shadow: 0px 2px 3px rgba(44, 49, 64, 0.1), 0px 0px 1px #0048ff;
  border-radius: 3px;
  border: 0px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;

  :deep(:not(.el-icon) svg) {
    margin-right: 7px;
    transition: all 0.1s;
  }

  &.is-round {
    border-radius: 30px;
  }

  &.is-plain {
    border: 1px solid #c8d6fa;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.04));
    // border-radius: 24px;
    box-shadow: none;
    background-color: #fff;
    color: #1753eb;
    font-weight: 500;

    &:not(.is-disabled):deep(svg) {
      fill: #1753eb;
    }

    &:not(.is-disabled):focus,
    &:not(.is-disabled):hover {
      border: 1px solid #5e85eb;
      color: #1343bf;

      :deep(svg) {
        fill: #1343bf;
      }
    }
  }

  &:not(.is-disabled):not(.is-plain):focus,
  &:not(.is-disabled):not(.is-plain):hover {
    background: #2f63eb;
    box-shadow: 0px 2px 3px rgba(44, 49, 64, 0.1), 0px 0px 1px #0048ff;
  }

  &:not(.is-disabled):not(.is-plain):active {
    background: #1343bf;
    box-shadow: 0px 2px 3px rgba(44, 49, 64, 0.1), 0px 0px 1px #0048ff;
  }

  &.is-disabled {
    color: #838d95 !important;
    background: #edf0f3 !important;
    box-shadow: 0px 2px 3px rgba(44, 49, 64, 0.1), 0px 0px 1px #a3a3a3 !important;
  }

  .elv-button__loading {
    display: block;
    width: 24px;
    height: 24px;
    // margin-right: 8px;
    animation: elv-loading-rotate 2s linear infinite;
  }
}
@keyframes elv-loading-rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
