import type { Component } from 'vue'

// 全局组件注册
import ElvButton from './ElvButton.vue'

const components: {
  [propName: string]: Component
} = {
  ElvButton
}

export default components
