export default {
  common: {
    elven: 'elven',
    team: '团队',
    email: '邮箱',
    emailAddress: '邮箱地址',
    password: '密码登录',
    captcha: '验证码登录',
    sendCaptchaCountdown: '秒',
    agreementText: '阅读并同意', // 同意协议文案
    userService: '服务条款',
    serviceAgreement: '《服务协议》', // 服务协议
    noData: '暂无数据',
    address: '地址',
    addresses: '地址',
    addressName: '地址名称',
    dateTime: '时间 / 日期和时间',
    amountIn: '收入金额',
    amountOut: '支出金额',
    FromTo: '付款方/收款方',
    currency: '货币',
    lineChart: '折线图',
    barChart: '柱状图',
    numberChart: '数字',
    equal: '等于',
    greaterThan: '大于',
    lessThan: '小于',
    range: '范围',
    start: '开始',
    end: '结束',
    to: '到',
    allRightsReserved: '版权所有',
    updatedOn: '更新于 ',
    dataSources: '数据来源',
    share: '分享',
    financials: '财务',
    business: '业务',
    valuation: '估值',
    project: '项目',
    projects: '项目',
    totalProject: '项目总数',
    list: '列表',
    results: '结果',
    result: '结果',
    group: '分组',
    groupView: '分组视图',
    secondaryGroup: '二级分组',
    tertiaryGroup: '三级分组',
    color: '颜色',
    block: '块',
    size: '大小',
    axise: '轴',
    rulesForSubtotal: '小计规则',
    keepGroup: '保持分组视图',
    choose: '选择',
    stick: '固定',
    unstick: '取消固定',
    hideColumn: '隐藏列',
    sortAscending: '生序排列',
    sortDescending: '降序排列',
    monitor: '监控',
    if: '如果',
    then: '然后',
    frequency: '频率',
    name: '名字',
    condition: '条件',
    soon: '即将到来',
    total: '总计',
    marketTotal: '总',
    addFilter: '新增筛选条件',
    pleaseChoose: '请选择',
    searchMetrics: '搜索指标',
    createdOn: '创建时间',
    lastExcution: '上次执行时间',
    runsEveryDay: '每天执行',
    everyDay: '每天',
    keepAlerts: '保留提醒',
    alertTime: '每天UTC 00:30发送。',
    account: '账户',
    contact: '联系人',
    openingBalance: '期初余额',
    closingBalance: '期末余额',
    journalEntries: '账目录入',
    debit: '借方',
    credit: '贷方',
    event: '事件',
    txCount: '交易计数',
    amount: '金额',
    millions: '百万',
    billions: '十亿',
    thousands: '千',
    individuals: '个位',
    reportUnit: '单位：{unit}美元',
    amountsUnit: '金额以{unit}美元为单位',
    willBeComingSoon: '即将上线',
    comingSoon: '即将来临',
    description: '描述',
    chain: '链',
    chains: '链',
    basedAddressOnChain: '基于{chainCount}条{chain}上的{addressCount}条{address}',
    allMetrics: '所有指标',
    addMetric: '添加指标',
    basicInfo: '基础信息',
    followTableSorting: '按照表格排序进行操作',
    numberOfGroups: '将被分成{count}个组',
    fieldTotalRule: '{field}总计规则：',
    fieldSubtotalRule: '{field}子总计规则：',
    Is: '是',
    'Is not': '不是',
    'Equal to': '等于',
    'More than': '大于',
    'Less than': '小于',
    'Within range': '在范围内',
    'Outside range': '在范围外',
    'Is empty': '为空',
    'Is not empty': '非空',
    shareTo: '分享至',
    selectAccountingPeriod: '选择会计期间：',
    createAccount: '创建帐户',
    verifyEmail: '验证您的电子邮件',
    continue: '继续',
    continueWithEmail: '通过 邮箱 继续操作',
    signInWithVerificationCode: '通过验证码继续操作',
    signInWithPassword: '通过密码继续操作',
    or: '或者',
    sameInAllEntities: '在所有主体中相同',
    expandControlPanel: '展开控制面板',
    copied: '已复制',
    moreInformation: '有关更多信息，请',
    selected: '选中',
    select: '选择',
    syncFailed: '同步失败',
    syncing: '同步中',
    sum: '总计',
    type: '类型',
    status: '状态',
    priority: '优先级',
    conditions: '条件',
    actions: '操作',
    date: 'Date',
    is: '是',
    accountName: '账户名称',
    accountType: '账户类型',
    contactType: '联系人类型',
    equals: '等于',
    doesNotEqual: '不等于',
    isGreaterThanOrEqualTo: '大于或等于',
    isGreaterThan: '大于',
    isLessThanOrEqualTo: '小于或等于',
    isLessThan: '小于',
    isBetween: '介于',
    on: '在',
    before: '之前',
    after: '之后',
    between: '介于',
    api: 'API',
    wallet: '钱包',
    wallets: '钱包',
    exchange: '交易所',
    exchanges: '交易所',
    fileName: '文件名',
    csvFiles: 'CSV文件',
    customizedAPI: '自定义API',
    entity: '主体',
    entities: '主体',
    users: '用户',
    month: '月',
    annually: '年',
    free: '免费',
    basic: '基础',
    pro: '专业版',
    enterprise: '企业版',
    unlimited: '无限制',
    CEO: '首席执行官 (CEO)',
    CFO: '首席财务官 (CFO)',
    CAO: '首席会计官 (CAO)',
    financeManager: '财务经理',
    accountingManager: '会计经理',
    financialAnalyst: '财务分析师',
    financialAdvisor: '财务顾问',
    taxSpecialist: '税务专家',
    others: '其他',
    cryptoNativeFirms: '加密原生公司',
    assetManagementFirms: '资产管理公司',
    onChainProjects: '链上项目',
    MiningStakingNodeOperators: '挖矿、质押和节点运营商',
    ventureCapitals: '风险投资',
    done: '完成',
    transactionsEntitiesUsers: '交易，主体，用户',
    enterprisePlanInfo: '适用于交易量大、支持和性能需求更高的大型组织。',
    projectSettings: '项目设置',
    entitySettings: '主体设置',
    general: '常规',
    integrations: '集成',
    member: '成员',
    members: '成员',
    PlanBilling: '计划和账单',
    security: '安全',
    customPricing: '自定义定价',
    subscription: '订阅',
    currentPlan: '当前计划',
    startDate: '开始日期',
    expirationDate: '到期日期',
    subscriptionStatus: '订阅状态',
    deleteMember: '删除成员',
    deleteEntity: '删除主体',
    administrator: '管理员',
    report: '报告',
    reports: '报告',
    settings: '设置',
    automation: '自动化',
    view: '查看',
    ledger: '账本',
    viewListPermission: '查看{operating}列表',
    viewDetailPermission: '查看{operating}详情',
    addNewPermission: '添加新的{operating}',
    editPermission: '编辑{operating}',
    deletePermission: '删除{operating}',
    exportPermission: '导出{operating}',
    generatePermission: '生成{operating}',
    reviewPermission: '审核{operating}',
    syncToErp: '同步到ERP',
    source: '来源',
    add: '添加',
    edit: '编辑',
    delete: '删除',
    export: '导出',
    review: '审核',
    rule: '规则',
    active: '激活',
    expired: '已过期',
    next: '下一步',
    prev: '上一步',
    skip: '跳过',
    alreadyUser: '已经是用户？',
    newToElven: '新用户？',
    file: '文件',
    uploadFile: '上传文件',
    bank: '银行',
    client: '客户端',
    activity: '活动',
    uploadAttachments: '上传附件',
    balance: '余额',
    '90D Revenue': '90天收入',
    '90D Expense': '90天支出',
    '90D Profit': '90天利润',
    ruleName: '规则名称',
    selectConditionsInfo: '如果满足以下所有条件：',
    selectCondition: '选择一个条件',
    performActions: '执行以下操作：',
    categorizeTransaction: '将交易分类为',
    otherBank: '其他银行'
  },
  report: {
    Address: '地址',
    'Address Name': '地址名称',
    Datetime: '时间 / 日期和时间',
    Currency: '货币',
    'Amount (In)': '收入金额',
    'Amount (Out)': '支出金额',
    'From/To': '付款方/收款方',
    Description: '描述',
    Debit: '借方',
    Credit: '贷方',
    Event: '事件',
    'Opening Balance': '期初余额',
    'Closing Balance': '期末余额',
    totalInflow: '总流入金额',
    totalOutflow: '总流出金额',
    totalDebit: '总借方金额',
    totalCredit: '总贷方金额',
    'Type of Assets': '资产类型',
    'Nominal Amount': '名义金额',
    'Market Value': '市场价值',
    '% of total net assets': '占总净资产的百分比',
    Multiples: '倍数',
    IRR: '内部收益率',
    'Cost basis': '成本基础',
    'Realized Proceeds': '已实现收益',
    'Cost basis of Realized Proceeds': '已实现收益成本基础',
    ON_CHAIN: '链上口径',
    OFF_CHAIN: '链下口径',
    GLOBAL: '合并口径',
    syncingWalletsOrExchanges: '同步中 钱包/交易所',
    synchronizingTotal: '{total}个来源正在同步',
    synchronizingTotals: '{progress}/{total}的来源正在同步',
    syncingSources: '同步来源 ({progress}/{total})',
    syncTransactionsTotal: '条交易已经导入，',
    syncTransactionsComplete: '同步完成，',
    syncComplete: '同步完成，请点击“重新生成报告”按钮更新报告。',
    deletedSourceTitle: '删除来源“{name}”',
    deletedSourceInfo: '将从此来源删除{total}个交易，此操作无法恢复。',
    batchEdit: '批量修改',
    totalContacts: '{total} 个联系人',
    contactType: '联系人类型',
    employee: '员工',
    vendor: '供应商',
    customer: '客户',
    investor: '投资者',
    community: '社区',
    debtor: '债务人',
    sponsor: '赞助商',
    memo: '备注',
    transaction: '交易',
    sent: '发送',
    received: '接收',
    internal: '内部转账',
    source: '来源',
    Source: '来源',
    Name: '名称',
    Memo: '备注',
    Assets: '资产',
    assets: '资产',
    assetsPortfolio: '资产组合',
    Quantity: '数量',
    'Cost Basis': '成本基础',
    'Fair Value': '公允价值',
    'Address/Bank Account': '地址/银行账户',
    'Significant holdings report': '重大持股报告',
    allSources: '所有来源',
    Transactions: '交易',
    transactionDetail: '交易详情',
    datetime: '时间 / 日期和时间',
    amount: '金额',
    from: '从',
    to: '到',
    accounting: '账单',
    transactionsTotal: '{total} 条交易',
    regenerateReports: '重新生成报告',
    regenerating: '正在重新生成',
    capitalGainORLoss: '资本收益/损失',
    marketValue: '市场价值',
    sourceName: '来源名称',
    key: 'Key',
    secret: 'Secret',
    passphrase: 'Passphrase',
    accountingPreview: '会计分录预览:',
    accountingPreviewInfo: '会计分录将在更新报表时生效',
    regenerateTheReports: '交易数据已更改，点击重新生成报告',
    regenerated: '已重新生成',
    generating: '正在生成',
    'Rule Engine Configure Accounting Entries and Cost Basis': '规则引擎配置会计分录和成本基础',
    'Generate Income Statement report': '生成损益表报告',
    'Generate Balance Sheet report': '生成资产负债表报告',
    'Generate Restrictions of Crypto Assets report': '生成加密资产限制报告',
    'Generate Cash Flow Statement report': '生成现金流量表报告',
    'Generate Crypto Assets Roll Forward report': '生成加密资产滚动报告',
    'Generate Significant Holdings report': '生成重大持股报告',
    'Generate note L1': '生成注释L1',
    'Generate note L2': '生成注释L2',
    Tabulating: '正在制表',
    'Reports have been regenerated successfully': '报告已成功重新生成',
    'Regenerate Failed, please check your transaction data': '重新生成失败，请检查您的交易数据',
    deleting: '正在删除',
    reportRegeneratedSuccess: '报告已重新生成',
    noSet: '未设置',
    transactionsRule: '{total}笔交易符合规则:',
    similarTransactions: '{total}笔相似交易:',
    setEvent: '设置事件',
    resolutionCenter: '解决中心',
    resolve: '解决',
    wellDone: '干得好',
    transactionsCategorized: '所有交易被分类。',
    duplicateTransactions: '没有可疑的重复交易',
    keepAll: '保留所有',
    keepAllConfirm: '这些交易看起来像是重复数据，您确定要保留它们吗？',
    possibleDuplicates: '可能的重复',
    possibleDuplicatesInfo: '{total}组交易彼此相似，它们可能是相同的吗？',
    possibleDuplicateInfo: '{total}组交易彼此相似，它们可能是相同的吗？',
    unknownEvent: '未知事件',
    unknownEventInfo: '{total}笔交易无法识别业务活动。',
    unknownEventsInfo: '{total}笔交易无法识别业务活动。',
    disposalExceedsBalance: '处置超过余额 - {currency}',
    disposalExceedsBalanceInfo: '在某些交易中，{currency}处置超过了余额。请验证来源或类别的准确性。',
    errorReport: '{sheet}计算错误',
    errorReportInfo: '报告中存在一些数字错误',
    discard: '丢弃',
    batchConfirm: '批量确认',
    batchSettingEvents: '批量设置事件',
    categorizeTransactionAs: '将交易分类为',
    transactionType: '交易类型',
    inflow: '流入',
    outflow: '流出',
    manuallyExecuteRules: '手动执行所有规则',
    rulesAreExecuting: '规则正在执行',
    operating: '经营活动',
    financing: '融资',
    investing: '投资',
    automaticallyReviewed: '自动标记为已审核',
    deleteRule: '删除规则',
    executeRule: '执行规则',
    unnamed: '未命名',
    unidentified: '未识别',
    named: '已命名',
    addYourWallet: '添加您的第一个钱包',
    addWalletInfo: '添加一个钱包以导入链上交易数据',
    addYourExchange: '添加您的第一个交易所',
    addExchangeInfo: '从交易所获取交易数据使用API或Auth',
    uploadCSVFile: '上传一个CSV文件',
    uploadCSVFileInfo: '通过上传CSV文件导入离线交易数据',
    welcomeUser: '欢迎， {userName}',
    startCreateProject: '现在开始创建您的第一个项目！',
    choosePlan: '选择订阅计划',
    applyForTrial: '申请试用',
    applyForTrialInfo: '感谢您的关注，请填写下面的表格，我们会尽快与您联系。',
    applyWaiting: '您已在等待列表中，请耐心等待我们的回复',
    yourOrganization: '您的组织',
    yourPosition: '您的职位',
    industry: '行业',
    numberOfHistoricalTransactions: '历史交易数量',
    otherCryptoFinancialProductsUsed: '使用的其他加密金融产品',
    howCanElvenHelpYou: 'Elven如何帮助您？',
    projectName: '项目名称',
    entityName: '主体名称',
    projectLogo: '项目Logo',
    entityLogo: '主体Logo',
    countryRegion: '国家/地区',
    dataStartingTime: '数据起始时间',
    costBasisMethodology: '成本基础方法',
    defaultCurrency: '默认货币',
    preferredCurrencyUnit: '首选货币单位',
    projectHasBeenCreated: '您的项目已创建！',
    getStartedOnFewEasySteps: '开始几个简单的步骤：',
    cancelRequestInfo: '您已申请试用，目前正在等待批准。您现在要取消吗？',
    needHelp: '需要帮助？',
    bookDemoTitle: '预约演示',
    bookDemoInfo: '您可以预约远程视频演示，我们的专家团队将指导您使用Elven。',
    ReadDocsTitle: '阅读文档',
    ReadDocsInfo: '阅读我们的全面帮助文档，它将指导您完成使用Elven的每一步。',
    ruleCount: '{total}条规则',
    startingFirstProject: '从创建您的第一个项目开始。',
    category: '类别',
    fileTemplate: '文件模板',
    totalLines: '总行数',
    unrecognizableLines: '不可识别行数',
    recognizedLines: '可识别行数',
    onlyGenericFormatCSV: '仅支持通用格式CSV，',
    onlyAcceptCSV: '仅接受符合Elven标准格式的CSV文件，',
    clickDownloadTemplate: '点击下载模板文件',
    parsingFile: '正在解析文件',
    parsingFailed: '解析失败',
    unableRecognizeYourFile: '无法识别您的文件',
    dragUploadOrBrowse: '拖放上传，或浏览文件。',
    uploadCSVMaxSize: '支持文件类型：csv，最大大小：100m',
    selectAccountInThisFile: '选择此文件中的账户',
    added: '添加时间',
    dataCutoffTime: '更新时间',
    editWallet: '编辑钱包',
    editExchange: '编辑交易所',
    editCSV: '编辑CSV',
    generalTransactions: '一般交易',
    bankTransactions: '银行交易',
    exchangeTrade: '交易所（交易）',
    exchangeDeposit: '交易所（充值）',
    exchangeWithdraw: '交易所（提现）',
    completeHistory: '完整历史数据',
    dataBefore: '之前的数据',
    dataAfter: '之后的数据',
    dataBetween: '之间的数据',
    transactionDataOption: '交易数据选项',
    addSources: '添加来源',
    addSourcesInfo: '为您的项目添加链上钱包或交易所，或上传CSV文件。',
    reviewTransactions: '审阅交易',
    reviewTransactionsInfo: '审阅交易并将每笔交易与正确的业务活动标签匹配。',
    generateReports: '生成报告',
    generateReportsInfo: '只需点击一下即可生成财务报表和加密资产披露！',
    addMemo: '添加备注',
    groupsDuplicatesTotal: '{total}组交易可能是重复的：'
  },
  title: {
    loginPageTitle: '强大的编辑功能使数据显示更加清晰。',
    loginPageTitle1: '创建我自己的筛选器！',
    loginToExploreFurther: '登录以进一步探索',
    setPasswordsTitle: '必须先设置密码，以后才能密码登录。',
    exceedsExportLimit: '超出导出限制',
    connectIntegrationInfo: '将Elven链接到您的{platform}账户，以便将Elven的账目录入数据自动同步到您的{platform}。',
    AccountInPlatform: '{platform}账户',
    disconnectWithPlatform: '断开与{platform}的连接',
    deleteAttachment: '删除附件',
    introducingElvenV3: 'Elven V3来了！'
  },
  button: {
    delete: '删除',
    save: '保存',
    saveAs: '另存为',
    saveFailed: '保存失败',
    discard: '丢弃',
    contactUs: '联系我们',
    filter: '筛选',
    editTable: '编辑表格',
    groupBy: '分组',
    addGroup: '添加分组',
    subGroup: '子分组',
    export: '导出',
    saveChanges: '保存更改',
    cancel: '取消',
    addCondition: '添加条件',
    ok: '确定',
    yes: '是的',
    no: '否',
    sendCaptchaText: '发送验证码', // 发送之前验证码按钮文案
    sentCaptchaButtonText: '重新发送', // 点击发送之后 验证码按钮文案
    login: '登录', // 登录按钮
    logOut: '退出登录', // 退出登录
    loginButtonTextInDetail: '点击登录', // 详情页登录按钮
    myAccount: '我的账号', // 我的账号
    bindEmail: '绑定邮箱', // 绑定邮箱
    saveAlert: '保存提醒',
    newAlert: '新建提醒',
    editAlert: '编辑提醒',
    clickView: '点击查看',
    connect: '连接',
    upload: '上传',
    type: '类型',
    sources: '来源',
    events: '事件',
    categories: '类别',
    currencies: '货币',
    contacts: '联系人',
    dateTime: '日期和时间',
    allTime: '全部时间',
    reset: '重置',
    deleteContact: '删除联系人',
    deleteContacts: '批量删除联系人',
    deleteAccount: '删除账户',
    deleteAccounts: '批量删除账户',
    edit: '编辑',
    confirm: '确认',
    backList: '返回列表',
    addRule: '添加规则',
    editRule: '编辑规则',
    addWallet: '添加钱包',
    addExchange: '添加交易所',
    upLoadCSV: '上传CSV',
    learnMore: '了解更多',
    create: '创建',
    applyNow: '立即申请',
    talkToSales: '联系销售',
    cancelRequest: '取消申请',
    submit: '提交',
    createProject: '创建项目',
    start: '开始',
    dismiss: '忽略',
    confirmCancel: '确认取消',
    addEntity: '添加主体',
    editEntity: '编辑主体',
    addMember: '添加成员',
    editMember: '编辑成员',
    add: '添加',
    changeEmail: '更改邮件地址',
    otherSign: '其他登录方式',
    signIn: '登录',
    signUp: '注册',
    getStarted: '开始',
    upgrade: '升级',
    markAllAsReviewed: '全部标记为已审核',
    markSelectedAsReviewed: '标记所选为已审核',
    iKnow: '我知道了',
    helpDocuments: '帮助和文档',
    switchToV3: '切换到V3',
    continueToUseV2: '继续使用V2',
    syncIntegration: '同步到 {platform}',
    disconnect: '断开连接',
    accountMappings: '账户映射',
    finish: '完成',
    resendInvitation: '重新发送邀请',
    goReview: '前往审核交易',
    reuploadFile: '重新上传文件',
    uploadAnotherFile: '上传另一个文件',
    uploadToExistingAccount: '上传到现有账户',
    createNewAccount: '创建新账户',
    loadMore: '加载更多'
  },
  message: {
    sendEmail: '发送通知到电子邮件',
    enterAlertName: '请输入您的提醒名称',
    confirmEmailLogin: '请确认您的电子邮件登录',
    loginTitle: '欢迎进入', // 登录弹框title
    loginEmailPlaceholder: '请输入正确的邮箱地址', // 登录弹框邮箱提示 和 校验提醒
    loginEmailError: '邮箱地址有误，请重新输入', // 校验提醒
    captchaPlaceholder: '请输入验证码', // 登录弹框验证码提示和校验提醒
    pleaseInputPassword: '请输入邮箱密码！',
    passwordError: '密码至少8个字符。一定要混合使用大写字母、小写字母、数字和符号。',
    captchaWarning: '请输入验证码',
    captchaError: '验证码错误，请重新输入',
    emailError: '邮箱或密码错误，请重新输入',
    pleaseInputEmail: '请输入邮箱！',
    pleaseInputName: '请输入您的名字! ',
    PleaseInputCode: '请输入邮箱验证码！',
    saveSuccess: '保存成功',
    copyFailed: '复制失败',
    copySuccessfully: '复制成功',
    signInSuccess: '登录成功！',
    signUpSuccess: '注册成功！',
    onlyScreeners: '你只能创建10个筛选器！',
    saveChangeGroup: '您已更改了分组视图，是否保存此更改？',
    pleaseEnterFilter: '请为筛选输入一个数值。',
    pleaseSelectFilter: '请为筛选选择一个指标。',
    rangeRequirements: '范围不符合要求。',
    alertEmpty: '提醒历史记录为空。',
    createdAlert: '您还没有创建任何提醒。',
    areYouSure: '确定吗?',
    saveChangeAlert: '您已更改提醒条件，是否保存更改？',
    reportOnlySee100: '只显示最近的100个项目，如果您需要查看所有数据，请',
    reportOnlySee20: '每个账户仅显示最新的20个项目，如果您需要查看所有数据，请',
    alertUpdatedSuccuss: '提醒更新成功!',
    alertCreatedSuccuss: '提醒创建成功!',
    fillAllBlanks: '请把所有的空都填上!',
    renameFailed: '文件重命名失败',
    pleaseEnterSourceName: '请输入此来源的名称。',
    pleaseEnterAccountName: '请输入账户名称。',
    enterSourceName: '输入的来源名称不一致。',
    noPermission: '您当前没有此权限。',
    pleaseEnterContactName: '请输入联系人的名称。',
    pleaseSelectContactType: '请选择当前未设置的联系人类型。',
    exportLimitMessage: '导出限制为每次1000条，可使用筛选器进行筛选后再导出。',
    editSuccess: '编辑成功',
    deleteSuccess: 'Delete Success',
    regenerateFailed: '重新生成失败，请检查您的交易数据',
    reportError: '报告已重新生成，但存在一些错误，请检查您的数据',
    transactionCategorized: '交易已分类',
    transactionIgnored: '交易已忽略',
    transactionKeepAll: '交易已保留',
    ruleEnabled: '规则已启用',
    ruleDisabled: '规则已禁用',
    priorityChanged: '优先级已更改',
    ruleSavedSuccessfully: '规则保存成功',
    pleaseWaitRules: '请等待规则执行结束',
    executeRuleMessage: '您是否要手动执行所有规则以处理所有交易数据？',
    deleteRuleMessage: '删除规则“{name}”？',
    addSixConditions: '添加最多六个条件',
    deleteMemberMessage: '删除成员“{email}”？',
    deleteEntityMessage: '删除主体“{name}”？',
    cancelSuccess: '取消成功',
    submitSuccess: '提交成功',
    uploadSuccess: '上传成功',
    uploadError: '上传失败，请检查您的文件',
    createProjectSuccess: '创建项目成功',
    addEntitySuccess: '添加主体成功',
    agreedTermsOfService: '第一次登录将自动创建一个帐户，这意味着您已阅读并同意',
    LoginAgreedTermsOfService: '您的登录和注册行为表示您已阅读并同意',
    pleaseEnterVerificationCode: '请输入已发送到<span>{email}</span>的验证码',
    resendAfter: '{tmp}秒后重新发送',
    loginInfoTitle: '您的链上会计合作伙伴',
    loginInfoDesc: '一站式加密会计和报告解决方案',
    upgradePro: '升级到专业版',
    needMoreEntities: '需要更多主体？',
    needMoreMembers: '需要更多成员？',
    reviewSuccess: '审核成功',
    someTransactionsReviewedInfo: '有一些交易不能被审阅',
    maximumTransactionsReviewedInfo: '单次批量审阅的交易上限是 {total} 条',
    reviewedSuccessfullyTotal: '{total} 条交易被成功审阅',
    restReviewTotal: '{total} 条交易需要手动指定业务活动标签',
    subscriptionExpired: '您的订阅已过期。请重新购买。',
    subscriptionLimitError: '您的使用量已超出限制。请升级您的计划。',
    syncIntegrationSuccess: '账户已同步到{platform}',
    disconnectWithPlatformInfo: '我们将清除与{platform}的对应关系。当您想要再次同步时，您需要重新关联。',
    authorizationFailed: '授权失败',
    syncIntegrationInfo: '正在生成会计分录，请等待生成完成后再次同步。',
    connectedSuccessfully: '成功连接到{platform}',
    successfullyDisconnected: '成功断开与{platform}的连接',
    accountMappingMessage: '同步完成后，账户映射将显示在此处。',
    success: '成功',
    resendInvitationEmailSuccess: '重新发送邀请邮件成功',
    sourceImportedSuccess: '您已成功导入{total}笔交易，其中{total}笔需要审核。',
    someLinesError: '由于格式错误、缺少内容等原因，此文件中的某些行未被识别。',
    importCSVErrorInfo: '找不到与{account}匹配的任何交易。没有交易可以导入。',
    transactionsWillBeImported: '将导入交易',
    transactionsWillBeImportedAccount: '将导入交易到{account}',
    uploadCSVWarningInfo: '只有与所选账户匹配的交易才会被导入，其他不相关的交易将被丢弃。',
    deleteAttachmentInfo: '您确定要删除“{fileName}”这个附件吗？',
    sourceSyncTimeoutInfo: '您的地址可能需要更长的同步时间。同步完成后，我们会通知您。',
    introducingElvenV3Info: 'Elven V3具有完整的账簿管理、可定制的科目表和全面的对账功能，非常适合复杂的会计和财务操作。'
  },
  input: {
    nameLabel: '你的名字',
    passwordsLabel: '密码(8-16个字符)',
    setPasswordsLabel: '设置密码(8-16个字符)'
  },
  placeholder: {
    enterEmail: ' 请输入您的电子邮件地址',
    enterNumber: ' 输入你的验证码',
    enterPassword: ' 输入你的密码',
    enterName: ' 输入你的名字',
    passwordCharacters: '8-16个字符',
    min: '最小',
    max: '最大'
  },
  country: {
    HongKong: '香港',
    Japan: '日本',
    Philippines: '菲律宾',
    Singapore: '新加坡',
    SouthKorea: '韩国',
    UnitedKingdom: '英国',
    UnitedStates: '美国'
  },
  bank: {
    星展银行: '星展银行',
    汇丰银行: '汇丰银行',
    中银香港: '中银香港',
    花旗银行: '花旗银行',
    东亚银行: '东亚银行',
    华侨银行: '华侨银行',
    工银亚洲: '工银亚洲',
    德意志银行: '德意志银行',
    渣打银行: '渣打银行',
    恒生银行: '恒生银行',
    富国银行: '富国银行',
    美国银行: '美国银行',
    大华银行: '大华银行',
    瑞士银行: '瑞士银行',
    巴克莱银行: '巴克莱银行',
    摩根大通: '摩根大通',
    招商银行: '招商银行',
    交通银行: '交通银行',
    中国银行: '中国银行',
    农业银行: '农业银行',
    建设银行: '建设银行',
    邮政储蓄银行: '邮政储蓄银行',
    工商银行: '工商银行',
    其他银行: '其他银行'
  },
  project: {
    nav: {
      financials: '财务',
      fundReports: '基金报告',
      metrics: '指标',
      charts: '图表',
      balanceSheet: '资产负债表',
      incomeStatement: '利润表',
      cashFlowStatement: '现金流量表',
      generalLedger: '总账表',
      transactions: '交易表',
      wallets: '钱包地址表',
      portfolioStatement: '投资组合表',
      totalReturn: '总收益率表',
      changeInNetAssets: '净资产变动表',
      tokenFlowStatement: '代币流量表',
      sources: '来源',
      accounts: '往来帐目',
      contacts: '联系',
      rules: '规则',
      disclosure: '披露表',
      SignificantHoldings: '重要持有',
      RestrictionsCryptoAssets: '加密资产限制',
      Restrictions: '限制',
      rollForward: '加密资产滚动转换',
      RollForward: '滚动转换',
      forReview: '待审核',
      duplicates: '重复',
      reviewed: '已审核',
      categorized: '已分类'
    }
  },
  bubbleGuide: {
    forReview: {
      step1:
        '待审核：您需要在此处先审核您的交易。<br/>重复项：您需要在此检查是否有重复的交易。<br/>已分类：已审核的交易。',
      step2: '通过点击勾选按钮，完成对单笔交易的审核。',
      step3: '批量审核按钮，您可以一起审核筛选出来的交易。'
    },
    duplicates: {
      step1: '如果您确认某笔交易是重复的，您可以点击此按钮将该交易设置为忽略。',
      step2: '如果您确认这些交易都不是重复的，您可以点击此按钮将它们从重复列表中删除。'
    },
    balanceSheet: {
      step1: '点击此按钮重新生成财务报告。'
    }
  },
  log: {
    addSource: "添加了数据源'{sourceName}'",
    editSource: "修改了数据源'{sourceName}'",
    deleteSource: "删除了数据源'{sourceName}'",
    uploadCsv: "上传了 CSV 文件'{fileName}'",
    reviewTransaction: "审阅了交易'{activityId}'",
    batchReviewTransaction: "批量审阅了{total}笔交易'{activityIds}'",
    editTransactionDetail: "修改了交易详情'{activityId}'",
    batchTransactionDetail: "批量修改了{total}笔交易'{activityIds}'",
    exportTransaction: '导出了{total}条交易',
    regenerateReports: '重新生成了报表',
    addContact: "添加了联系人'{contactName}'",
    batchAddContact: "批量添加了{total}个联系人 '{contactNames}'",
    editContact: "修改了联系人'{contactName}'",
    deleteContact: "删除了联系人'{contactName}'",
    batchDeleteContact: "批量删除了{total}个联系人'{contactNames}'",
    addRule: "创建了规则'{ruleName}'",
    editRule: "修改了规则'{ruleName}'",
    deleteRule: "删除了规则'{ruleName}'",
    manuallyExecuteRules: '手动执行了规则引擎',
    addAccount: "添加了账户'{accountName}'",
    editAccount: "修改了账户'{accountName}'",
    deleteAccount: "删除了账户'{accountName}'",
    batchDeleteAccount: "批量删除了{total}个账户'{accountNames}'"
  },
  menus: {
    'My Projects': '我的项目',
    sources: '数据源',
    Reports: '报告',
    reports: '财务报表',
    transactions: '交易管理',
    ledger: '总账',
    contacts: '联系人管理',
    automation: '规则引擎',
    settings: '项目设置',
    Fundamentals: '基础面',
    'Money Flows': '资金流',
    Social: '社交媒体',
    alert: '我的提醒',
    rules: '规则设置',
    history: '历史记录',
    market: '市场',
    marketMap: '市场地图',
    emergingSpaces: '新兴领域',
    heatmap: '热度图',
    screeners: '我的筛选器'
  }
}
